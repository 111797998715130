import { Controller } from '@hotwired/stimulus'
import type { Chart as ChartInstanceType, ChartType, ChartItem } from 'chart.js'
import type { CustomerJourneyData, CustomerJourneyStatistic, CustomerJourneyVisit } from '../types/customer_journey'
import ChartColours from '../helpers/chart_colours'
import { Chart } from '../chart-js-sankey'
// Connects to data-controller="ex-customer-journey"

export default class extends Controller {
  static targets = ['canvas', 'leastPopularTitle', 'leastPopularValue', 'mostPopularTitle', 'mostPopularValue']
  static values = { data: Array }

  declare readonly leastPopularTitleTarget: HTMLElement
  declare readonly leastPopularValueTarget: HTMLElement
  declare readonly mostPopularTitleTarget: HTMLElement
  declare readonly mostPopularValueTarget: HTMLElement
  declare readonly canvasTarget: HTMLCanvasElement
  declare readonly dataValue: CustomerJourneyData[]
  declare chartInstance: ChartInstanceType
  declare chart: ChartType
  declare selectedData: CustomerJourneyStatistic[]
  declare selectedPeriodIndex: number
  declare selectedTargetType: keyof CustomerJourneyData
  declare selectedVisits: keyof CustomerJourneyVisit
  declare mappedColours: any
  declare deselectedKeys: string[]

  mapDataToColours (): void {
    const fromKeys = new Set()

    this.dataValue.forEach((d: CustomerJourneyData) => {
      Object.values(d).forEach((nestedObject: CustomerJourneyVisit) => {
        ['all', 'engaged'].forEach(key => {
          nestedObject[key as keyof CustomerJourneyVisit].forEach((item: CustomerJourneyStatistic) => fromKeys.add(item.fromKey))
        })
      })
    })
    const colourMap: any = {}
    Array.from(fromKeys).forEach((target: any, index: number) => { colourMap[target] = ChartColours[index % ChartColours.length] })
    this.mappedColours = colourMap
  }

  // Hack to ensure only the first nodes label is displayed (Sankey library does not provide a simple way of doing this)
  setLabels (): any {
    const labels: any = {}
    this.selectedData.forEach((item: CustomerJourneyStatistic) => {
      if (item.from === `${item.fromKey} (0)`) {
        const label = item.fromKey.length > 14 ? `${item.fromKey.substring(0, 14)}...` : item.fromKey
        labels[item.from] = label
      } else {
        labels[item.from] = ' '
        labels[item.to] = ' '
      }
    })

    return labels
  }

  formattedChartData (): any[] {
    return [{
      data: this.selectedData,
      colorFrom: ({ raw: { fromKey } }: any) => this.mappedColours[fromKey],
      colorTo: ({ raw: { toKey } }: any) => this.mappedColours[toKey],
      colorMode: 'from',
      size: 'max',
      color: ({ raw: { fromKey } }: any) => this.mappedColours[fromKey],
      labels: this.setLabels()
    }]
  }

  setCustomerJourneyStatistics ({ detail }: any): void {
    this.selectedPeriodIndex = detail.periodIndex
    this.selectedVisits = detail.visits === 'visits_count' ? 'all' : 'engaged'

    this.updateChart()
  }

  setTargetType ({ target }: any): void {
    this.selectedTargetType = this.selectedTargetType === 'ExAllocation' ? 'ExGroup' : 'ExAllocation'
    target.innerHTML = this.selectedTargetType === 'ExAllocation' ? 'Show EX Groups' : 'Show EX Sensors'
    this.dispatch('setTargetType', { detail: { selectedTargetType: this.selectedTargetType } })
    this.updateChart()
  }

  setSelectedData (): void {
    this.selectedData =
      this.dataValue[this.selectedPeriodIndex][this.selectedTargetType][this.selectedVisits].filter(item => !this.deselectedKeys.includes(item.fromKey) && !this.deselectedKeys.includes(item.toKey))
  }

  updateChart (): void {
    this.setSelectedData()
    this.chartInstance.data.datasets = this.formattedChartData()
    this.chartInstance.update()
    this.updateHeroStats()
  }

  filterSelectedData (key: string): void {
    const allKeys = [...new Set(this.selectedData.map((item: any): string => item.fromKey))]

    if (this.deselectedKeys.includes(key)) {
      this.deselectedKeys = this.deselectedKeys.filter(k => k !== key)
    } else {
      // Ensure at least 2 targets are displayed
      if (allKeys.length < 3) return

      this.deselectedKeys.push(key)
    }
    this.updateChart()
  }

  updateHeroStats (): void {
    const allKeys = [...new Set(this.selectedData.map((item: any): string => item.fromKey))]

    // All interaction 0's for current selection
    const firstInteractionsIndex = allKeys.length * (allKeys.length - 1)
    const firstInteractions = this.selectedData.slice(0, firstInteractionsIndex)

    const flowTally: Record<string, number> = {}
    allKeys.forEach((k: string) => {
      flowTally[k] = firstInteractions.filter((d) => d.fromKey === k).reduce((a, item) => a + item.flow, 0)
    })
    const totalFlow = Object.values(flowTally).reduce((accumulator: number, value: number) => accumulator + value, 0)

    Object.keys(flowTally).forEach((k: string) => {
      flowTally[k] = flowTally[k] / totalFlow * 100
    })

    const sorted = Object.keys(flowTally).sort((a, b) => { return -(flowTally[a] - flowTally[b]) })

    this.mostPopularTitleTarget.innerHTML = sorted[0]
    this.mostPopularValueTarget.innerHTML = `${Math.round(flowTally[sorted[0]])}%`

    this.leastPopularTitleTarget.innerHTML = sorted.slice(-1)[0]
    this.leastPopularValueTarget.innerHTML = `${Math.round(flowTally[sorted.slice(-1)[0]])}%`
  }

  connect (): void {
    // Make whole time engaged the default selection
    this.selectedPeriodIndex = 0
    this.selectedTargetType = 'ExAllocation'
    this.selectedVisits = 'engaged'
    this.deselectedKeys = []
    this.setSelectedData()
    this.mapDataToColours()
    this.updateHeroStats()

    const ctx: ChartItem | null = this.canvasTarget.getContext('2d')

    if (ctx === null) return

    // eslint-disable-next-line no-new
    this.chartInstance = new Chart(ctx, {
      // @ts-expect-error: Allow customSankey as chart type
      type: 'customSankey',
      data: {
        datasets: this.formattedChartData()
      },
      options: {
        layout: {
          padding: {
            left: 120
          }
        },
        plugins: {
          legend: {
            display: true,
            onClick: (_a, legendItem, _c) => {
              this.filterSelectedData(legendItem.text)
            },
            labels: {
              generateLabels: (): any => {
                const labels: Array<{ text: string, fillStyle: string, hidden: boolean }> = []
                this.dataValue[this.selectedPeriodIndex][this.selectedTargetType][this.selectedVisits].forEach(({ fromKey }: CustomerJourneyStatistic): any => {
                  if (!labels.map(l => l?.text).includes(fromKey)) {
                    labels.push({
                      text: fromKey,
                      fillStyle: this.mappedColours[fromKey],
                      hidden: this.deselectedKeys.includes(fromKey)
                    })
                  }
                })
                return labels
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                const fromKey: string = context.dataset.data[context.dataIndex].fromKey
                const toKey: string = context.dataset.data[context.dataIndex].toKey
                return `${fromKey} -> ${toKey}`
              }
            }
          }
        }
      }
    })
  }
}
