import { type HTMLEvent } from '../types/html_event'
import ExStackedBarChart from './ex_stacked_bar_chart_controller'
import { type ChartData } from 'chart.js/auto'

// Connects to data-controller="summary-dwell-distribution-chart"
export default class extends ExStackedBarChart {
  static targets = ['totalCheckbox', 'engagedCheckbox']
  static values = { totalsData: Object, engagedData: Object }

  totalsDataValue!: ChartData
  engagedDataValue!: ChartData

  declare readonly totalCheckboxTarget: HTMLInputElement
  declare readonly engagedCheckboxTarget: HTMLInputElement
  declare readonly hasTotalCheckboxTarget: boolean

  connect (): void {
    if (this.hasTotalCheckboxTarget) {
      this.totalCheckboxTarget.checked = false
    }
    this.engagedCheckboxTarget.checked = true
    this.dataValue = this.engagedDataValue
    super.connect()
  }

  toggleCheckbox (event: HTMLEvent): void {
    if (!this.hasTotalCheckboxTarget) return
    if (event.currentTarget === this.totalCheckboxTarget) {
      this.engagedCheckboxTarget.checked = false
      this.chart.data = this.totalsDataValue
    } else {
      this.totalCheckboxTarget.checked = false
      this.chart.data = this.engagedDataValue
    }
    this.chart.update()
  }
}
