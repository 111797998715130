import { Controller } from '@hotwired/stimulus'
import { type HeatmapData, type HeatmapPoint, type HeatmapStatistic } from '../types/map_statistics'
import type { HTMLEvent } from '../types/html_event'
import mapData from '../heatmap/map_data'

export default class extends Controller {
  static targets = [
    'mapsDropdown', 'mapsDropdownButton', 'selectedMaps',
    'visitsDropdown', 'visitsDropdownButton', 'selectedVisits',
    'periodDropdown', 'periodDropdownButton', 'selectedPeriod'
  ]

  static values = { heatmapdata: Object, timezone: String }

  declare readonly periodDropdownTarget: HTMLElement
  declare readonly periodDropdownButtonTarget: HTMLElement
  declare readonly selectedPeriodTarget: HTMLElement

  declare readonly visitsDropdownTarget: HTMLElement
  declare readonly visitsDropdownButtonTarget: HTMLElement
  declare readonly selectedVisitsTarget: HTMLElement

  declare readonly mapsDropdownTarget: HTMLElement
  declare readonly mapsDropdownButtonTarget: HTMLElement
  declare readonly selectedMapsTarget: HTMLElement

  declare readonly heatmapdataValue: HeatmapData
  declare readonly timezoneValue: string
  declare currentMap: HTMLElement
  declare currentVisits: HTMLElement
  declare currentPeriod: HTMLElement
  declare visits: string | undefined
  declare eventDayIndex: number
  declare targetType: 'ExAllocation' | 'ExGroup' | undefined
  declare heatmapElm: HTMLElement | null
  declare heatmapContainerElm: HTMLElement | null
  declare treemapContainerElm: HTMLElement | null
  declare noDataElm: HTMLElement | null
  declare headingElm: HTMLElement | null
  declare eventDayStartsAt: number
  declare eventDayEndsAt: number

  connect (): void {
    this.currentMap = this.selectedMapsTarget
    this.currentVisits = this.selectedVisitsTarget
    this.currentPeriod = this.selectedPeriodTarget
    this.visits = this.selectedVisitsTarget.dataset.visits
    this.eventDayIndex = 0
    this.targetType = 'ExAllocation'
    this.heatmapElm = document.getElementById('heatmap')
    this.heatmapContainerElm = document.getElementById('heatmap-container')
    this.treemapContainerElm = document.getElementById('treemap-container')
    this.noDataElm = document.getElementById('nodata')
    this.headingElm = document.getElementById('heading')
    // Need to wrap this in setTimeout for the dispatch to work on connect
    setTimeout(() => { this.setHeatmapData() }, 1)
  }

  setMaps ({ target }: HTMLEvent): void {
    const { mapType } = target.dataset
    if (this.heatmapContainerElm === null || this.treemapContainerElm === null) return
    this.currentMap.classList.remove('ex-statistics-dropdownItem-selected')
    target.classList.add('ex-statistics-dropdownItem-selected')
    this.currentMap = target

    if (mapType === 'all') {
      this.heatmapContainerElm.hidden = false
      this.treemapContainerElm.hidden = false
    } else if (mapType === 'treemap') {
      this.treemapContainerElm.hidden = false
      this.heatmapContainerElm.hidden = true
    } else if (mapType === 'heatmap') {
      this.treemapContainerElm.hidden = true
      this.heatmapContainerElm.hidden = false
    }
    this.mapsDropdownButtonTarget.innerHTML = target.innerHTML
    this.toggleMapsDropdown()
  }

  setVisits ({ target }: any): void {
    const { visits } = target.dataset
    if (visits !== this.visits) {
      this.currentVisits.classList.remove('ex-statistics-dropdownItem-selected')
      target.classList.add('ex-statistics-dropdownItem-selected')
      this.currentVisits = target
      this.visits = visits
      this.visitsDropdownButtonTarget.innerHTML = target.innerHTML
      this.setHeatmapData()
    }
    this.toggleVisitsDropdown()
  }

  setPeriod ({ target }: any): void {
    const { eventDayIndex, eventDayStartsAt, eventDayEndsAt } = target.dataset
    if (eventDayIndex !== this.eventDayIndex) {
      this.currentPeriod.classList.remove('ex-statistics-dropdownItem-selected')
      target.classList.add('ex-statistics-dropdownItem-selected')
      this.currentPeriod = target
      this.eventDayIndex = parseInt(eventDayIndex)
      this.eventDayStartsAt = parseInt(eventDayStartsAt)
      this.eventDayEndsAt = parseInt(eventDayEndsAt)
      this.periodDropdownButtonTarget.innerHTML = target.innerHTML
      this.setHeatmapData()
    }
    this.togglePeriodDropdown()
  }

  setTarget ({ target }: any): void {
    this.targetType = this.targetType === 'ExAllocation' ? 'ExGroup' : 'ExAllocation'
    target.innerHTML = this.targetType === 'ExAllocation' ? 'Show EX Groups' : 'Show EX Sensors'
    this.setHeatmapData()
  }

  getPointValue ({ x, y, all_value: allValue, engaged_value: engagedValue }: HeatmapPoint): { x: number, y: number, value: number } {
    return { x, y, value: this.visits === 'engaged' ? engagedValue : allValue }
  }

  checkInEventDay ({ timestamp }: HeatmapStatistic): boolean {
    return timestamp >= this.eventDayStartsAt && timestamp <= this.eventDayEndsAt
  }

  timestampToTimezone (timestamp: number): string {
    return new Date(timestamp * 1000).toLocaleString('en-GB', { timeZone: this.timezoneValue, month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' })
  }

  setHeatmapData (): void {
    if (this.heatmapElm === null || this.noDataElm === null || this.headingElm === null) return

    const heatmapStatistics: HeatmapStatistic[] = []

    const preWindow = 2400
    const postWindow = 1200
    const step = 900

    const exAllocationNameLookup = new Map<number, string>()
    for (const exAllocation of this.heatmapdataValue.ex_allocations) {
      exAllocationNameLookup.set(exAllocation.id, exAllocation.name)
    }

    for (const eventDay of this.heatmapdataValue.event_days) {
      let timestamp = eventDay.starts_at
      while (timestamp < eventDay.ends_at) {
        const windowStart = timestamp - preWindow
        const windowEnd = timestamp + postWindow
        const points = eventDay.points.filter((point) => point.time >= windowStart && point.time <= windowEnd)
        points.forEach((point) => { point.ex_allocation_name = exAllocationNameLookup.get(point.ex_allocation_id) ?? 'Unknown' })

        heatmapStatistics.push({
          all_min: points.length > 0 ? points.reduce((min, point) => Math.min(min, point.all_value), Number.MAX_SAFE_INTEGER) : 0,
          all_max: points.length > 0 ? points.reduce((max, point) => Math.max(max, point.all_value), Number.MIN_SAFE_INTEGER) : 0,
          engaged_min: points.length > 0 ? points.reduce((min, point) => Math.min(min, point.engaged_value), Number.MAX_SAFE_INTEGER) : 0,
          engaged_max: points.length > 0 ? points.reduce((max, point) => Math.max(max, point.engaged_value), Number.MIN_SAFE_INTEGER) : 0,
          timestamp,
          points
        })
        timestamp += step
      }
    }

    const filteredData = this.eventDayIndex === 0 ? heatmapStatistics : heatmapStatistics.filter(this.checkInEventDay.bind(this))
    const mappedData = mapData(filteredData, this.visits ?? 'all', this.timezoneValue)
    if (mappedData.length === 0) {
      this.heatmapElm.hidden = true
      this.noDataElm.hidden = false
      this.headingElm.innerText = 'No data available'
    } else {
      this.heatmapElm.hidden = false
      this.noDataElm.hidden = true
      this.headingElm.innerText = 'Maps'
      this.dispatch('setHeatmapData', { detail: { data: mappedData, targetType: this.targetType } })
      this.dispatch('setTreemapData', { detail: { data: filteredData, visits: this.visits } })
    }
  }

  toggleVisitsDropdown (): void {
    this.periodDropdownTarget.hidden = true
    this.mapsDropdownTarget.hidden = true
    this.visitsDropdownTarget.hidden = !this.visitsDropdownTarget.hidden
  }

  togglePeriodDropdown (): void {
    this.visitsDropdownTarget.hidden = true
    this.mapsDropdownTarget.hidden = true
    this.periodDropdownTarget.hidden = !this.periodDropdownTarget.hidden
  }

  toggleMapsDropdown (): void {
    this.visitsDropdownTarget.hidden = true
    this.periodDropdownTarget.hidden = true
    this.mapsDropdownTarget.hidden = !this.mapsDropdownTarget.hidden
  }
}
