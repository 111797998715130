import { Controller } from '@hotwired/stimulus'
import { type HTMLEvent } from '../types/html_event'

export default class extends Controller {
  static targets = ['dialog']

  declare readonly dialogTarget: HTMLDialogElement

  openDialog (): void {
    this.dialogTarget.showModal()
  }

  closeDialog (event: HTMLEvent): void {
    if (event.target === event.currentTarget) {
      this.dialogTarget.close()
    }
  }
}
