import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  declare readonly contentTarget: HTMLElement

  toggleDropdown (): void {
    this.contentTarget.classList.toggle('show')
  }
}
