import ExBaseFlowchartController from './ex_base_flowchart_controller'
import type { SensorCurve } from '../flowchart/SensorCurve'

export default class ExFlowchartController extends ExBaseFlowchartController {
  static FULL_CIRCLE_RADIANS = 2 * Math.PI
  static ORIGINAL_HEIGHT = 424 // The height that the x,y sensor locations are based on

  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly valuelegendTargets: HTMLInputElement[]
  declare _currentPeriod: HTMLElement
  declare _currentVisits: HTMLElement
  declare _ranges: Array<[number, number]>
  declare _hiddenRangeIndexes: number[]

  static targets = ['checkbox', 'valuelegend']

  connect (): void {
    this._visits = 'engaged_visits_count'
    this._targetType = 'ExAllocation'
    super.connect()
  }

  setFlowStatistics ({ detail }: any): void {
    this._periodIndex = detail.periodIndex
    this._visits = detail.visits
    this._setupCurves()
  }

  setTarget ({ target }: any): void {
    this._targetType = this._targetType === 'ExAllocation' ? 'ExGroup' : 'ExAllocation'
    target.innerHTML = this._targetType === 'ExAllocation' ? 'Show EX Groups' : 'Show EX Sensors'
    this._setup()
  }

  setHiddenRangeIndexes (): void {
    this._hiddenRangeIndexes = this.checkboxTargets.filter(checkbox => !checkbox.checked).map(checkbox => {
      return checkbox.dataset.value != null ? parseInt(checkbox.dataset.value) : 0
    })
  }

  _shouldNotDrawCurve (curve: SensorCurve): boolean {
    const newArray = this._hiddenRangeIndexes.map(index => this._ranges[index])
    const rangeIndex = newArray.findIndex(([start, end]) => curve.value >= start && curve.value <= end)
    return (rangeIndex !== -1)
  }

  _setup (): void {
    this.setHiddenRangeIndexes()
    super._setup()
  }

  _setRanges (): void {
    super._setRanges()
    const delta = this._maxVisits - this._minVisits
    const percs = [0, 0.2, 0.4, 0.6, 0.8, 1]
    let previousValue = 0
    this._ranges = percs.map((perc) => {
      const maxValue = Math.round(this._minVisits + perc * delta)
      const numberElm = this.valuelegendTargets.find(vl => vl.dataset.value === String(perc.toFixed(1)))
      if (numberElm !== undefined) numberElm.innerText = String(maxValue)
      const range: [number, number] = [previousValue, maxValue]
      previousValue = maxValue
      return range
    })
  }
}
