import { Controller } from '@hotwired/stimulus'
declare const GIF: any

// Connects to data-controller="canvas-recorder"
export default class extends Controller {
  static values = { canvasClass: String, otherControllerName: String }

  static targets = [
    'showRecordingControlsButton', 'closeRecordingControlsButton', 'recordingControlsContainer',
    'startRecordingButton', 'pauseRecordingButton', 'resumeRecordingButton',
    'finishRecordingButton', 'recordingNotifier', 'previewContainer',
    'resultContainer', 'downloadGIFButton', 'downloadVideoButton',
    'otherControllerElement'
  ]

  static classes = ['hide', 'recordingBorder']

  declare readonly showRecordingControlsButtonTarget: HTMLElement
  declare readonly closeRecordingControlsButtonTarget: HTMLElement
  declare readonly recordingControlsContainerTarget: HTMLElement
  declare readonly startRecordingButtonTarget: HTMLElement
  declare readonly pauseRecordingButtonTarget: HTMLElement
  declare readonly resumeRecordingButtonTarget: HTMLElement
  declare readonly finishRecordingButtonTarget: HTMLElement
  declare readonly recordingNotifierTarget: HTMLElement
  declare readonly previewContainerTarget: HTMLElement
  declare readonly resultContainerTarget: HTMLElement
  declare readonly downloadGIFButtonTarget: HTMLElement
  declare readonly downloadVideoButtonTarget: HTMLElement
  declare readonly otherControllerElementTarget: HTMLElement
  declare readonly hasOtherControllerElementTarget: boolean

  declare readonly hideClass: string
  declare readonly recordingBorderClass: string

  declare canvasClassValue: string
  declare otherControllerNameValue: string
  declare intervalId: ReturnType<typeof setInterval>
  declare canvas: HTMLCanvasElement
  declare ctx: CanvasRenderingContext2D
  declare gif: any
  declare recordedBlobs: Blob[]
  declare stream: MediaStream
  declare mediaRecorder: MediaRecorder

  connect (): void {
    this.makeElementDraggable(this.recordingControlsContainerTarget)
  }

  showRecordingControls (): void {
    this.showRecordingControlsButtonTarget.classList.add(this.hideClass)
    this.closeRecordingControlsButtonTarget.classList.remove(this.hideClass)
    this.recordingControlsContainerTarget.classList.remove(this.hideClass)
    this.startRecordingButtonTarget.classList.remove(this.hideClass)
  }

  closeRecordingControls (): void {
    this.showRecordingControlsButtonTarget.classList.remove(this.hideClass)
    this.closeRecordingControlsButtonTarget.classList.add(this.hideClass)
    this.recordingControlsContainerTarget.classList.add(this.hideClass)
  }

  startRecording (): void {
    this.createGIFInstance()
    this.createMediaRecorderInstance()
    this.beginRecording()
    this.setPlayPause(true)

    this.closeRecordingControlsButtonTarget.classList.add(this.hideClass)
    this.startRecordingButtonTarget.classList.add(this.hideClass)
    this.pauseRecordingButtonTarget.classList.remove(this.hideClass)
    this.finishRecordingButtonTarget.classList.remove(this.hideClass)
    this.recordingNotifierTarget.classList.remove(this.hideClass)
    this.recordingControlsContainerTarget.classList.add(this.recordingBorderClass)
    this.previewContainerTarget.classList.remove(this.hideClass)
    this.resultContainerTarget.classList.add(this.hideClass)
    this.downloadGIFButtonTarget.classList.add(this.hideClass)
    this.downloadVideoButtonTarget.classList.add(this.hideClass)
  }

  pauseRecording (): void {
    this.stopRecording()
    this.setPlayPause(false)

    this.pauseRecordingButtonTarget.classList.add(this.hideClass)
    this.resumeRecordingButtonTarget.classList.remove(this.hideClass)
    this.recordingNotifierTarget.classList.add(this.hideClass)
    this.recordingControlsContainerTarget.classList.remove(this.recordingBorderClass)
  }

  resumeRecording (): void {
    this.beginRecording()
    this.setPlayPause(true)

    this.pauseRecordingButtonTarget.classList.remove(this.hideClass)
    this.resumeRecordingButtonTarget.classList.add(this.hideClass)
    this.recordingNotifierTarget.classList.remove(this.hideClass)
    this.recordingControlsContainerTarget.classList.add(this.recordingBorderClass)
  }

  finishRecording (): void {
    this.stopRecording()
    this.createMedia()
    this.setPlayPause(false)

    this.closeRecordingControlsButtonTarget.classList.remove(this.hideClass)
    this.startRecordingButtonTarget.classList.remove(this.hideClass)
    this.pauseRecordingButtonTarget.classList.add(this.hideClass)
    this.resumeRecordingButtonTarget.classList.add(this.hideClass)
    this.finishRecordingButtonTarget.classList.add(this.hideClass)
    this.recordingNotifierTarget.classList.add(this.hideClass)
    this.recordingControlsContainerTarget.classList.remove(this.recordingBorderClass)
    this.previewContainerTarget.classList.add(this.hideClass)
    this.resultContainerTarget.classList.remove(this.hideClass)
    this.downloadGIFButtonTarget.classList.remove(this.hideClass)
    this.downloadVideoButtonTarget.classList.remove(this.hideClass)
  }

  createGIFInstance (): void {
    this.canvas = document.querySelector(this.canvasClassValue)!
    this.ctx = this.canvas.getContext('2d')!

    this.gif = new GIF({
      workers: 2,
      workerScript: '/gif.worker.js',
      quality: 10,
      width: this.canvas.width,
      height: this.canvas.height
    })

    const downloadGIFButtonAnchor = this.downloadGIFButtonTarget.firstElementChild as HTMLAnchorElement
    this.gif.on('finished', function (blob: Blob) {
      const blobUrl = URL.createObjectURL(blob)
      downloadGIFButtonAnchor.href = blobUrl
    })
  }

  createMediaRecorderInstance (): void {
    this.recordedBlobs = []
    this.stream = this.canvas.captureStream()
    this.mediaRecorder = new MediaRecorder(this.stream)
    this.mediaRecorder.ondataavailable = (e: BlobEvent) => this.recordedBlobs.push(e.data)
    this.mediaRecorder.onstop = (e: Event) => { this.exportVideo(new Blob(this.recordedBlobs, { type: 'video/webm' })) }
    const previewVideoElement = this.previewContainerTarget.firstElementChild as HTMLVideoElement
    previewVideoElement.srcObject = this.stream
  }

  createMedia (): void {
    this.gif.render()
    this.mediaRecorder.stop()
  }

  beginRecording (): void {
    this.intervalId = setInterval(this.addFrame.bind(this), 1000)
    if (this.mediaRecorder.state === 'paused') {
      this.mediaRecorder.resume()
    } else {
      this.mediaRecorder.start()
    }
  }

  stopRecording (): void {
    clearInterval(this.intervalId)
    this.mediaRecorder.pause()
  }

  addFrame (): void {
    this.gif.addFrame(this.ctx, { copy: true, delay: 1000 })
  }

  exportVideo (blob: Blob) {
    const resultElement = document.getElementById('result') as HTMLVideoElement
    const downloadVideoButtonAnchor = this.downloadVideoButtonTarget.firstElementChild as HTMLAnchorElement
    const blobUrl = URL.createObjectURL(blob)
    resultElement.src = blobUrl
    downloadVideoButtonAnchor.href = blobUrl
  }

  setPlayPause (expectedPlayingState: boolean): void {
    if (!this.hasOtherControllerElementTarget) return
    const targetController: any = this.application.getControllerForElementAndIdentifier(this.otherControllerElementTarget, this.otherControllerNameValue)
    if (targetController.playing != expectedPlayingState) {
      this.dispatch('playPause', {})
    }
  }

  makeElementDraggable (elmnt: HTMLElement): void {
    let pos1 = 0; let pos2 = 0; let pos3 = 0; let pos4 = 0

    elmnt.onmousedown = dragMouseDown

    function dragMouseDown (e: MouseEvent): void {
      e = e || window.event
      e.preventDefault()
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      document.onmousemove = elementDrag
    }

    function elementDrag (e: MouseEvent): void {
      e = e || window.event
      e.preventDefault()
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      elmnt.style.top = (elmnt.offsetTop - pos2) + 'px'
      elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px'
    }

    function closeDragElement (): void {
      document.onmouseup = null
      document.onmousemove = null
    }
  }
}
