import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="event-comparison"
export default class extends Controller {
  static targets = ['submit', 'tooManySelected']

  declare readonly submitTarget: HTMLElement
  declare readonly tooManySelectedTarget: HTMLElement

  declare checkboxes: NodeListOf<HTMLInputElement>

  connect (): void {
    this.checkboxes = this.element.querySelectorAll('.comparisonCheckbox > input[type="checkbox"]')
    this.submitTarget.hidden = true
    this.tooManySelectedTarget.hidden = true
    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('click', this.updateDisplay.bind(this))
    })
  }

  updateDisplay (): void {
    const checked = Array.from(this.checkboxes).filter((checkbox) => checkbox.checked)
    if (checked.length < 2) {
      this.submitTarget.hidden = true
      this.tooManySelectedTarget.hidden = true
    } else if (checked.length > 6) {
      this.submitTarget.hidden = true
      this.tooManySelectedTarget.hidden = false
    } else {
      this.submitTarget.hidden = false
      this.tooManySelectedTarget.hidden = true
    }
  }
}
