import type { FormattedHeatmapStatistic, HeatmapPoint, HeatmapStatistic } from '../types/map_statistics'

function mapData (data: HeatmapStatistic[], visits: string, timezone: string): FormattedHeatmapStatistic[] {
  return data.map((heatmapStatistic: HeatmapStatistic) => {
    return {
      minKey: visits === 'engaged' ? heatmapStatistic.engaged_min : heatmapStatistic.all_min,
      maxKey: visits === 'engaged' ? heatmapStatistic.engaged_max : heatmapStatistic.all_max,
      min: heatmapStatistic.all_min,
      max: heatmapStatistic.all_max,
      timestamp: timestampToTimezone(heatmapStatistic.timestamp, timezone),
      points: heatmapStatistic.points.map((e) => getPointValue(visits, e))
    }
  })
}

function timestampToTimezone (timestamp: number, timezone: string): string {
  return new Date(timestamp * 1000).toLocaleString('en-GB', { timeZone: timezone, month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

function getPointValue (visits: string, { x, y, all_value: allValue, engaged_value: engagedValue }: HeatmapPoint): { x: number, y: number, value: number } {
  return { x, y, value: visits === 'engaged' ? engagedValue : allValue }
}

export default mapData
