import ExBaseHeatmapController from './ex_base_heatmap_controller'

export default class ExHeatmapController extends ExBaseHeatmapController {
  static targets = [
    'circledot', 'legendMax', 'legendMin',
    'line', 'overlayTimestampCheckbox', 'pause', 'play',
    'text', 'textend', 'textstart', 'timeline'
  ]

  declare readonly playTarget: HTMLElement
  declare readonly pauseTarget: HTMLElement
  declare readonly lineTarget: HTMLElement
  declare readonly textTarget: HTMLElement
  declare readonly textstartTarget: HTMLElement
  declare readonly textendTarget: HTMLElement
  declare readonly timelineTarget: HTMLElement
  declare readonly circledotTarget: HTMLElement
  declare readonly legendMinTarget: HTMLElement
  declare readonly legendMaxTarget: HTMLElement
  declare readonly overlayTimestampCheckboxTarget: HTMLInputElement

  declare canvas: HTMLCanvasElement
  declare ctx: CanvasRenderingContext2D
  declare ranSetUp: boolean
  declare originalHeight: number
  declare currentFrame: number
  declare intervalId: ReturnType<typeof setInterval> | null
  declare timelineMouseIsDown: boolean
  declare playing: boolean
  declare heatmapMaxIndex: number
  declare heatmapInstance: any
  declare scaleX: (num: number) => number
  declare scaleY: (num: number) => number

  connect (): void {
    super.connect()
    this.currentFrame = -1
    this.intervalId = null
    this.timelineMouseIsDown = false
    this.playing = false
    this.heatmapMaxIndex = 0
    this.timelineTarget.addEventListener('mousedown', this.timelineMouseDown.bind(this))
    document.addEventListener('mousemove', this.timelineMouseMove.bind(this))
    document.addEventListener('mouseup', this.timeLineMouseUp.bind(this))
  }

  toggleTimestamp (): void {
    this.showFrame()
  }

  update (): void {
    this.nextFrame()
  }

  setHeatmapData ({ detail: { data, targetType } }: any): void {
    const lastMaxIndex = this.heatmapMaxIndex
    const lastFrameTime = this.heatmapData[this.currentFrame] !== undefined ? this.heatmapData[this.currentFrame].timestamp : null
    this.heatmapData = data
    this.targetType = targetType
    this.heatmapMaxIndex = this.heatmapData.length - 1
    this.textstartTarget.textContent = this.heatmapData[0].timestamp
    this.textendTarget.textContent = this.heatmapData[this.heatmapMaxIndex].timestamp
    if (lastMaxIndex !== this.heatmapMaxIndex || lastFrameTime !== this.heatmapData[this.currentFrame].timestamp) {
      this.currentFrame = -1
    } else {
      this.currentFrame -= 1
    }
    super.setHeatmapData(data)
  }

  startInterval (): void {
    this.nextFrame()
    this.intervalId = setInterval(this.nextFrame.bind(this), 1000)
    this.playing = true
  }

  stopInterval (): void {
    if (this.intervalId !== null) clearInterval(this.intervalId)
    this.playing = false
  }

  timelineMouseMove (event: MouseEvent): void {
    if (!this.timelineMouseIsDown) return
    this.setTimelineFromEvent(event)
  }

  timelineMouseDown (event: MouseEvent): void {
    this.timelineMouseIsDown = true
    this.setTimelineFromEvent(event)
  }

  timeLineMouseUp (): void {
    this.timelineMouseIsDown = false
  }

  setTimelineFromEvent (event: MouseEvent): void {
    const { left } = this.timelineTarget.getBoundingClientRect()
    const offsetX = event.clientX - left
    const percentage = (offsetX / this.timelineTarget.offsetWidth)
    this.currentFrame = Math.round(this.heatmapMaxIndex * percentage)
    if (this.currentFrame < 0) this.currentFrame = 0
    if (this.currentFrame > this.heatmapMaxIndex) this.currentFrame = this.heatmapMaxIndex
    this.showFrame()
    this.setTimeline(percentage * 100)
  }

  nextFrame (): void {
    if (this.currentFrame >= this.heatmapMaxIndex) {
      this.playPause()
      this.currentFrame = -1
      return
    }
    this.currentFrame += 1
    this.showFrame()
    this.setTimeline()
  }

  showFrame (): void {
    const { timestamp, points, minKey, maxKey, min, max } = this.heatmapData[this.currentFrame]
    this.setTime(timestamp)
    this.setLegend(minKey, maxKey)
    this.setPoints(min, max, points)
    this.setSensorIcons()
    this.setMapBackground()
    this.setTimestamp(timestamp)
    this.dispatch('nextFrame', { detail: this.currentFrame })
  }

  setLegend (min: number, max: number): void {
    this.legendMinTarget.textContent = min.toLocaleString('en-GB')
    this.legendMaxTarget.textContent = max.toLocaleString('en-GB')
  }

  playPause (): void {
    if (this.playing) {
      this.playTarget.hidden = false
      this.pauseTarget.hidden = true
      this.stopInterval()
    } else {
      this.playTarget.hidden = true
      this.pauseTarget.hidden = false
      this.startInterval()
    }
  }

  setTimeline (percentage = (this.currentFrame / this.heatmapMaxIndex) * 100): void {
    if (percentage < 0) percentage = 0
    if (percentage > 100) percentage = 100
    const percentageString: string = `${percentage}%`
    this.setLine(percentageString)
    this.setText(percentageString)
  }

  setLine (percentage: string): void {
    this.lineTarget.style.background = `linear-gradient(to right, black ${percentage}, grey ${percentage})`
    this.circledotTarget.style.left = percentage
  }

  setText (percentage: string): void {
    this.textTarget.style.left = percentage
    const { left: textLeft, right: textRight } = this.textTarget.getBoundingClientRect()
    const { right: textStartRight } = this.textstartTarget.getBoundingClientRect()
    const { left: textEndLeft } = this.textendTarget.getBoundingClientRect()
    const { left: timeLineLeft, right: timeLineRight } = this.timelineTarget.getBoundingClientRect()
    if (textLeft < timeLineLeft || textRight > timeLineRight) {
      this.textTarget.style.visibility = 'hidden'
      this.textstartTarget.style.visibility = 'visible'
      this.textendTarget.style.visibility = 'visible'
    } else {
      this.textTarget.style.visibility = 'visible'
      if (textLeft < textStartRight + 10) {
        this.textstartTarget.style.visibility = 'hidden'
      } else {
        this.textstartTarget.style.visibility = 'visible'
      }
      if (textRight > textEndLeft - 10) {
        this.textendTarget.style.visibility = 'hidden'
      } else {
        this.textendTarget.style.visibility = 'visible'
      }
    }
  }

  setTime (timestamp: string): void {
    this.textTarget.textContent = timestamp
  }

  setTimestamp (timestamp: string): void {
    if (!this.overlayTimestampCheckboxTarget.checked) return
    const { devicePixelRatio } = window
    const scaledWidth = this.canvas.width / devicePixelRatio
    const scaledHeight = this.canvas.height / devicePixelRatio

    this.ctx.fillStyle = 'rgba(0, 0, 0, .5)'
    this.ctx.fillRect(0, scaledHeight - 30, scaledWidth, 30)

    this.ctx.fillStyle = '#ffffff'
    this.ctx.font = '14px Arial'
    const drawTextAtX = scaledWidth / 2
    const drawTextAtY = scaledHeight - 11
    this.ctx.fillText(timestamp, drawTextAtX, drawTextAtY)
  }
}
