export default [
  '#31a354', '#e6550e', '#009688',
  '#756bb1', '#dadaeb', '#ff49e8',
  '#3182bd', '#c8e9bf', '#9e9ac7',
  '#74c476', '#e6550e', '#fdae6b',
  '#636363', '#9ecae0', '#d9d9d9',
  '#31a354', '#fd8d3d', '#fdd0a2',
  '#c6dbef', '#e85f81', '#6f9192',
  '#fd8d3c', '#fdd0a2', '#bcbddc',
  '#08d6dc', '#969696', '#ed350e',
  '#ffeb3b', '#c726e4', '#b8d506',
  '#bdbdbd', '#d5a006', '#89712a',
  '#2a41bf', '#a2241b', '#ffa49b'
]
