import { Chart, DoughnutController } from 'chart.js'
// default options

function roundedRect (ctx, x, y, width, height, radius) {
  let TL, TR, BR, BL

  // Minimum horizontal value: half of the width
  const mH = r => Math.min(r, width / 2)
  // Minimum vertical value: half of the width
  const mV = r => Math.min(r, height / 2)

  if (radius instanceof Array) {
    // [TL, TR, BR, BL]
    TL = radius[0]
    TR = radius[1]
    BR = radius[2]
    BL = radius[3]
  } else {
    TL = TR = BR = BL = radius
  }

  ctx.beginPath()

  ctx.moveTo(x + mH(TL), y)
  ctx.lineTo(x + width - mH(TR), y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + mV(TR))
  ctx.lineTo(x + width, y + height - mV(BR))
  ctx.quadraticCurveTo(x + width, y + height, x + width - mH(BR), y + height)
  ctx.lineTo(x + mH(BL), y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - mV(BL))
  ctx.lineTo(x, y + mV(TL))
  ctx.quadraticCurveTo(x, y, x + mH(TL), y)

  ctx.closePath()
}

class GaugeController extends DoughnutController {
  getValuePercent ({ minValue, data }, value) {
    const min = minValue || 0
    const max = data[data.length - 1] || 1
    const length = max - min
    const percent = (value - min) / length
    return percent
  }

  getWidth (chart) {
    return chart.chartArea.right - chart.chartArea.left
  }

  getHeight (chart) {
    return chart.chartArea.bottom - chart.chartArea.top
  }

  getTranslation (chart) {
    const { chartArea } = chart
    const { offsetX, offsetY } = this
    const centerX = (chartArea.left + chartArea.right) / 2
    const centerY = (chartArea.top + chartArea.bottom) / 2
    const dx = (centerX + offsetX)
    const dy = (centerY + offsetY)
    return { dx, dy }
  }

  getAngle ({ chart, valuePercent }) {
    const { rotation, circumference } = chart.options
    return rotation + (circumference * valuePercent)
  }

  drawNeedle (ease) {
    if (!this.chart.animating) {
      ease = 1
    }
    const { ctx, config } = this.chart
    const { innerRadius, outerRadius } = this
    const { value } = config.data.datasets[this.index]
    const {
      radiusPercentage,
      widthPercentage,
      lengthPercentage,
      color
    } = config.options.needle

    const width = this.getWidth(this.chart)
    const needleRadius = (radiusPercentage / 100) * width
    const needleWidth = (widthPercentage / 100) * width
    const needleLength = (lengthPercentage / 100) * (outerRadius - innerRadius) + innerRadius

    // center
    const { dx, dy } = this.getTranslation(this.chart)

    function calculateValue (percent) {
      const slope = Math.PI / 100
      const intercept = Math.PI
      return slope * percent + intercept
    }

    const angle = calculateValue(value)
    // draw
    ctx.save()
    ctx.translate(dx, dy)
    ctx.rotate(angle)
    ctx.fillStyle = color

    // draw circle
    ctx.beginPath()
    ctx.ellipse(0, 0, needleRadius, needleRadius, 0, 0, 2 * Math.PI)
    ctx.fill()

    // draw needle
    ctx.beginPath()
    ctx.moveTo(0, needleWidth / 2)
    ctx.lineTo(needleLength, 0)
    ctx.lineTo(0, -needleWidth / 2)
    ctx.fill()

    ctx.restore()
  }

  drawValueLabel (ease) { // eslint-disable-line no-unused-vars
    if (!this.chart.config.options.valueLabel.display) {
      return
    }
    const { ctx, config } = this.chart
    const {
      defaultFontFamily
    } = config.options
    const { value } = config.data.datasets[this.index]
    const {
      fontSize,
      color,
      backgroundColor,
      borderRadius,
      padding,
      bottomMarginPercentage
    } = config.options.valueLabel

    const width = this.getWidth(this.chart)
    const height = this.getHeight(this.chart)
    const bottomMargin = (bottomMarginPercentage / 100) * width

    const valueText = `${value}%`
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    if (fontSize) {
      ctx.font = `${fontSize}px ${defaultFontFamily}`
    }

    const { width: textWidth } = ctx.measureText(valueText)
    // approximate height until browsers support advanced TextMetrics
    const textHeight = Math.max(ctx.measureText('m').width, ctx.measureText('\uFF37').width)
    const x = -(padding.left + textWidth / 2)
    const y = -(padding.top + textHeight / 2)
    const w = (padding.left + textWidth + padding.right)
    const h = (padding.top + textHeight + padding.bottom)

    // center
    let { dx, dy } = this.getTranslation(this.chart)
    // add rotation
    const rotation = this.chart.options.rotation % (Math.PI * 2.0)
    dx += bottomMargin * Math.cos(rotation + Math.PI / 2)
    dy += -(height / 3) * Math.sin(rotation + Math.PI / 2)

    // draw
    ctx.save()
    ctx.translate(dx, dy)

    // draw background
    ctx.beginPath()
    roundedRect(ctx, x, y, w, h, borderRadius)
    ctx.fillStyle = backgroundColor
    ctx.fill()

    // draw value text
    ctx.fillStyle = color || config.options.defaultFontColor
    const magicNumber = 0.075 // manual testing
    ctx.fillText(valueText, 0, textHeight * magicNumber)

    ctx.restore()
  }

  // overrides
  update (reset) {
    const dataset = this.chart.config.data.datasets[this.index]
    dataset.minValue = dataset.minValue || 0

    const meta = this.getMeta()
    const initialValue = {
      valuePercent: 0
    }
    if (reset) {
      meta.previous = null
      meta.current = initialValue
    } else {
      dataset.data.sort((a, b) => a - b)
      meta.previous = meta.current || initialValue
      meta.current = {
        valuePercent: this.getValuePercent(dataset, dataset.value)
      }
    }
    DoughnutController.prototype.update.call(this, reset)
  }

  updateElement (arc, index, reset) {
    DoughnutController.prototype.updateElement.call(this, arc, index, reset)
    const dataset = this.getDataset()
    const { data } = dataset
    const previousValue = index === 0 ? dataset.minValue : data[index - 1]
    const value = data[index]
    const startAngle = this.getAngle({ chart: this.chart, valuePercent: this.getValuePercent(dataset, previousValue) })
    const endAngle = this.getAngle({ chart: this.chart, valuePercent: this.getValuePercent(dataset, value) })
    const circumference = endAngle - startAngle

    arc._model = {
      ...arc._model,
      startAngle,
      endAngle,
      circumference
    }
  }

  draw (ease) {
    DoughnutController.prototype.draw.call(this, ease)
    this.drawNeedle(ease)
    this.drawValueLabel(ease)
  }
}

GaugeController.id = 'gauge'
GaugeController.defaults = DoughnutController.defaults

Chart.register(GaugeController)

export default Chart
