/* eslint-disable @typescript-eslint/strict-boolean-expressions */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Chart } from 'chart.js'
import { Flow, SankeyController } from 'chartjs-chart-sankey'
import { valueOrDefault } from 'chart.js/helpers'

class CustomSankey extends SankeyController {
  _drawNodes (): void {
    const ctx = this._ctx
    const nodes = this._nodes || new Map()
    const dataset = this.getDataset() /* SankeyControllerDatasetOptions */
    const size = 'max'
    const { xScale, yScale } = this._cachedMeta
    const borderWidth = valueOrDefault(dataset.borderWidth, 1)
    const nodeWidth = valueOrDefault(dataset.nodeWidth, 10)
    ctx.save()
    ctx.strokeStyle = dataset.borderColor
    ctx.lineWidth = borderWidth
    for (const node of nodes.values()) {
      if (node.out === 0 && node.in === 0) continue

      ctx.fillStyle = node.color
      const x = xScale.getPixelForValue(node.x)
      const y = yScale.getPixelForValue(node.y)

      const max = Math[size](node.in || node.out, node.out || node.in)
      const height = Math.abs(yScale.getPixelForValue(node.y + max) - y)
      if (borderWidth) {
        ctx.strokeRect(x, y, nodeWidth, height)
      }
      ctx.fillRect(x, y, nodeWidth, height)
    }
    ctx.restore()
  }

  _drawLabels (): void {
    const ctx = this._ctx
    const nodes = this._nodes || new Map()
    const dataset: any = this.getDataset()
    const size = 'max'
    const borderWidth: number = valueOrDefault(dataset.borderWidth, 1)
    const nodeWidth: number = valueOrDefault(dataset.nodeWidth, 10)
    const labels = dataset.labels
    const { yScale } = this._cachedMeta

    if (yScale === undefined) return

    ctx.save()
    const chartArea = this.chart.chartArea
    for (const node of nodes.values()) {
      if (node.out === 0 && node.in === 0) continue

      const x = 0
      const y = yScale.getPixelForValue(node.y)

      const max = Math[size](node.in || node.out, node.out || node.in)
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const height = Math.abs(yScale.getPixelForValue(node.y + max) - y)
      const label = labels?.[node.key] || node.key
      let textX = x
      ctx.fillStyle = node.color
      ctx.textBaseline = 'middle'
      if (x < chartArea.width / 2) {
        ctx.textAlign = 'left'
        textX += nodeWidth + borderWidth + 4
      } else {
        ctx.textAlign = 'right'
        textX -= borderWidth + 4
      }
      this._drawLabel(label, y, height, ctx, textX)
    }
    ctx.restore()
  }

  draw (): void {
    const ctx = this._ctx
    const data = this.getMeta().data.filter((d) => d.$context.raw.flow > 0) || [] /* Array<Flow> */
    // Set node colors
    const active = []
    for (let i = 0, ilen = data.length; i < ilen; ++i) {
      const flow = data[i] /* Flow at index i */

      flow.from.color = flow.options.colorFrom
      flow.to.color = flow.options.colorTo
      if (flow.active) {
        active.push(flow)
      }
    }

    for (const flow of active) {
      flow.from.color = flow.options.colorFrom
      flow.to.color = flow.options.colorTo
    }
    /* draw SankeyNodes on the canvas */
    this._drawNodes()

    for (let i = 0, ilen = data.length; i < ilen; ++i) {
      data[i].draw(ctx)
    }
    this._drawLabels()
  }
}

CustomSankey.defaults = {
  animations: {
    progress: {
      easing: 'linear',
      duration: (ctx) => {
        if (ctx.raw.flow === 0) return 0
        return ctx.type === 'data' ? (ctx.parsed._custom.x - ctx.parsed.x) * 180 : undefined
      },
      delay: (ctx) => {
        if (ctx.type !== 'data' || ctx.raw.flow === 0) {
          return undefined
        }
        const data = ctx.dataset.data.filter((d) => d.flow > 0)
        const delayOffset = data.indexOf(ctx.raw)
        return ctx.parsed.x * 500 + delayOffset * 20
      }
    }
  }
}

CustomSankey.id = 'customSankey'
Chart.register(CustomSankey, Flow)

export { Chart }
