import ExBaseFlowchartController from './ex_base_flowchart_controller'
import type { Data } from '../types/flowchart'

// Connects to data-controller="ex-realtime-flowchart"
export default class extends ExBaseFlowchartController {
  connect (): void {
    const selectedVisitsElement: HTMLElement = document.querySelector("div[data-ex-realtime-target='selectedVisits'") as HTMLElement
    this._visits = this.translateVisitType(selectedVisitsElement.dataset.visits ?? 'all')
    this._targetType = 'ExAllocation'
    super.connect()
  }

  setFlowchartData (data: { detail: Data }): void {
    this.dataValue = data.detail
    this._setup()
  }

  setVisits (data: { detail: string }): void {
    this._visits = this.translateVisitType(data.detail)
  }

  translateVisitType (visitType: string): string {
    return visitType === 'all' ? 'visits_count' : 'engaged_visits_count'
  }
}
