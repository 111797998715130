import DialogController from './dialog_controller'
import { type HTMLEvent } from '../types/html_event'

// Connects to data-controller="company-filter"
export default class extends DialogController {
  static targets = ['company']

  declare readonly companyTargets: HTMLButtonElement[]

  filter (event: HTMLEvent): void {
    const query = (event.target as HTMLInputElement).value.toLowerCase()
    this.companyTargets.forEach((company) => {
      company.classList.add('companiesDialog-select-hidden')
      const name = company.innerText.toLowerCase()
      if (name.includes(query)) {
        company.classList.remove('companiesDialog-select-hidden')
      }
    })
  }
}
