import { Controller } from '@hotwired/stimulus'
import type { ExTargetStatistics, ExStatistic } from '../types/ex_statistics'

export default class extends Controller {
  static targets = [
    'targetDropdown', 'targetDropdownButton', 'selectedTarget',
    'periodDropdown', 'periodDropdownButton', 'selectedPeriod'
  ]

  static values = {
    statistics: Object
  }

  declare readonly periodDropdownTarget: HTMLElement
  declare readonly periodDropdownButtonTarget: HTMLElement
  declare readonly selectedPeriodTarget: HTMLElement

  declare readonly targetDropdownTarget: HTMLElement
  declare readonly targetDropdownButtonTarget: HTMLElement
  declare readonly selectedTargetTarget: HTMLElement

  declare readonly statisticsValue: { ex_allocations: ExTargetStatistics[], ex_groups: ExTargetStatistics[] }
  declare filteredStatisticsValue: ExStatistic | undefined
  declare currentTarget: HTMLElement
  declare currentPeriod: HTMLElement
  declare targetId: string | undefined
  declare targetType: string | undefined
  declare eventDayIndex: string
  declare eventDayStartsAt: string
  declare eventDayEndsAt: string

  connect (): void {
    this.currentTarget = this.selectedTargetTarget
    this.currentPeriod = this.selectedPeriodTarget
    this.targetId = this.selectedTargetTarget.dataset.targetId
    this.targetType = this.selectedTargetTarget.dataset.targetType
    this.eventDayIndex = '0'
    // Need to wrap this in setTimeout for the dispatch to work on connect
    setTimeout(() => { this.setStatistics() }, 1)
  }

  setTarget ({ target }: any): void {
    const { targetId, targetType } = target.dataset
    if (targetId !== this.targetId || targetType !== this.targetType) {
      this.currentTarget.classList.remove('ex-statistics-dropdownItem-selected')
      target.classList.add('ex-statistics-dropdownItem-selected')
      this.currentTarget = target
      this.targetId = targetId
      this.targetType = targetType
      this.targetDropdownButtonTarget.innerHTML = target.innerHTML
      this.setStatistics()
    }
  }

  setPeriod ({ target }: any): void {
    const { eventDayIndex, eventDayStartsAt, eventDayEndsAt } = target.dataset
    if (eventDayIndex !== this.eventDayIndex) {
      this.currentPeriod.classList.remove('ex-statistics-dropdownItem-selected')
      target.classList.add('ex-statistics-dropdownItem-selected')
      this.currentPeriod = target
      this.eventDayIndex = eventDayIndex
      this.eventDayStartsAt = eventDayStartsAt
      this.eventDayEndsAt = eventDayEndsAt
      this.periodDropdownButtonTarget.innerHTML = target.innerHTML
      this.setStatistics()
    }
  }

  setStatistics (): void {
    const statisticsForTargetType = this.targetType === 'ExAllocation' ? this.statisticsValue.ex_allocations : this.statisticsValue.ex_groups
    const selectedTarget: ExTargetStatistics | undefined =
      statisticsForTargetType
        .find(({ id }: ExTargetStatistics) => id === Number(this.targetId))

    if (selectedTarget !== undefined) {
      const {
        conversion_ratio: conversionRatio,
        dwell: dwellStatistics,
        visitors: visitorStatistics,
        dwell_time_distribution: dwellDistributionStatistics
      } = selectedTarget
      const heroStatistics = selectedTarget.hero_statistics[Number(this.eventDayIndex)]
      let selectedConversionRatio = conversionRatio[conversionRatio.length - 1]

      // TODO - Improve how this is implemented.
      // Probably need to improve the way that the json is structured.
      if (this.eventDayIndex !== '0') {
        dwellStatistics.labels = [dwellStatistics.labels[Number(this.eventDayIndex) - 1]]
        selectedConversionRatio = conversionRatio[Number(this.eventDayIndex) - 1]
        dwellStatistics.datasets[0].data = [dwellStatistics.datasets[0].data[Number(this.eventDayIndex) - 1]]
        if (dwellStatistics.datasets[1]) {
          dwellStatistics.datasets[1].data = [dwellStatistics.datasets[1].data[Number(this.eventDayIndex) - 1]]
        }
        visitorStatistics.datasets.forEach((vs: any) => {
          vs.data = vs.data.filter(({ time }: any) => Number(time) >= Number(this.eventDayStartsAt) && Number(time) <= Number(this.eventDayEndsAt))
        })
      }
      this.targetDropdownTarget.hidden = true
      this.periodDropdownTarget.hidden = true
      this.dispatch('setVisitorStatistics', { detail: visitorStatistics })
      this.dispatch('setDwellStatistics', { detail: dwellStatistics })
      this.dispatch('setHeroStatistics', { detail: heroStatistics })
      this.dispatch('setDwellDistributionStatistics', { detail: dwellDistributionStatistics })
      this.setConversionRatio(selectedConversionRatio)
    }
  }

  setConversionRatio (conversionRatio: number): void {
    const conversionRatioTarget = document.getElementById('conversion-ratio') as HTMLElement
    conversionRatioTarget.innerHTML = `${conversionRatio}%`
  }

  toggleTargetDropdown (): void {
    this.targetDropdownTarget.hidden = !this.targetDropdownTarget.hidden
  }

  togglePeriodDropdown (): void {
    this.periodDropdownTarget.hidden = !this.periodDropdownTarget.hidden
  }
}
