import { Controller } from '@hotwired/stimulus'
import type { HTMLEvent } from '../types/html_event'

// Connects to data-controller="admin-multi-select"
export default class extends Controller {
  static targets = ['toggleButton', 'checkbox', 'unselectedContainer', 'selectedContainer']
  static classes = ['hide']

  declare readonly hideClass: string
  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly toggleButtonTargets: HTMLInputElement[]
  declare readonly unselectedContainerTarget: HTMLElement
  declare readonly selectedContainerTarget: HTMLElement

  toggleSelection (e: HTMLEvent): void {
    e.preventDefault()
    const { targetId } = e.target.dataset
    const checkboxTarget = this.checkboxTargets.find((target) => target.value === targetId) as HTMLInputElement
    const toggleButtonTarget = e.target as HTMLButtonElement

    if (targetId != null) {
      this._toggleCheckbox(checkboxTarget)
      this._moveToggleButton(checkboxTarget, toggleButtonTarget)
      // TODO too slow
      // this._sortToggleButtons()
    }
  }

  _toggleCheckbox (checkboxTarget: HTMLInputElement): void {
    if (checkboxTarget.checked) {
      checkboxTarget.removeAttribute('checked')
    } else {
      checkboxTarget.setAttribute('checked', 'checked')
    }
  }

  _moveToggleButton (checkboxTarget: HTMLInputElement, toggleButtonTarget: HTMLButtonElement): void {
    if (checkboxTarget.checked) {
      this.selectedContainerTarget.appendChild(toggleButtonTarget)
    } else {
      this.unselectedContainerTarget.appendChild(toggleButtonTarget)
    }
  }

  _sortToggleButtons (): void {
    this._sortToggleButtonsForContainer(this.unselectedContainerTarget)
    this._sortToggleButtonsForContainer(this.selectedContainerTarget)
  }

  _sortToggleButtonsForContainer (container: HTMLElement): void {
    const toggleButtons = Array.from(container.children) as HTMLButtonElement[]

    Array.from(toggleButtons).sort((a, b) => {
      const aSearchLabel = a.dataset.searchLabel ?? ''
      const bSearchLabel = b.dataset.searchLabel ?? ''
      return aSearchLabel.localeCompare(bSearchLabel)
    }).forEach((toggleButton) => {
      container.appendChild(toggleButton)
    })
  }
}
