import { Controller } from '@hotwired/stimulus'
import type { HTMLInputEvent } from '../types/html_event'

// Connects to data-controller="multi-select"
export default class extends Controller {
  static targets = ['toggleButton', 'checkbox']
  static classes = ['hide']

  declare readonly hideClass: string
  declare readonly checkboxTarget: HTMLInputElement
  declare readonly toggleButtonTargets: HTMLInputElement[]

  toggleSelection (e: HTMLInputEvent): void {
    e.preventDefault()
    this.toggleButtonTargets.forEach(target => target.classList.toggle(this.hideClass))
    if (this.checkboxTarget.checked) {
      this.checkboxTarget.removeAttribute('checked')
    } else {
      this.checkboxTarget.setAttribute('checked', 'checked')
    }
  }
}
