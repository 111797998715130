import 'chartjs-adapter-date-fns'
import LineChartBase from './line_chart_base'

// Connects to data-controller="ex-comparison-line-chart"
export default class extends LineChartBase {
  static targets = ['container']
  declare readonly containerTarget: HTMLElement

  setComparisonLineChartStatistics ({ detail: { statistics, selection: { chart, chartType } } }: any): void {
    if (chartType !== 'visitors') {
      this.containerTarget.hidden = true
    } else if (chartType === 'visitors') {
      this.containerTarget.hidden = false
    }

    this.chart.legend!.options.title.text = `${chart.charAt(0).toUpperCase()}${chart.slice(1)}`
    this.chart.data = statistics
    this.chart.update()
  }

  legend (): any {
    return {
      align: 'start',
      position: 'right',
      title: {
        display: true,
        font: { size: 16 },
        text: 'Sensors'
      },
      labels: {
        borderRadius: 6,
        boxHeight: 12,
        boxWidth: 12,
        padding: 15,
        useBorderRadius: true
      }
    }
  }
}
