import { Controller } from '@hotwired/stimulus'
import { type StatisticsImport, type FormattedHeatmapPoint, type FormattedHeatmapStatistic } from '../types/map_statistics'

declare const h337: any

export default class ExBaseHeatmapController extends Controller {
  static FULL_CIRCLE_RADIANS = 2 * Math.PI

  static targets = ['heatmap', 'point', 'image']

  declare readonly heatmapTarget: HTMLElement
  declare readonly pointTargets: HTMLElement[]
  declare readonly imageTarget: HTMLImageElement

  declare canvas: HTMLCanvasElement
  declare ctx: CanvasRenderingContext2D
  declare targetType: 'ExAllocation' | 'ExGroup' | undefined
  declare ranSetUp: boolean
  declare originalHeight: number
  declare heatmapInstance: any
  declare scaleX: (num: number) => number
  declare scaleY: (num: number) => number

  declare heatmapData: FormattedHeatmapStatistic[]

  connect (): void {
    this.targetType = 'ExAllocation'
    this.ranSetUp = false
    this.originalHeight = 424 // When creating the map, the points are all relative to this height
    this.heatmapInstance = null
    this.heatmapData = []
  }

  scaleNumberFactory (oldMax: number, newMax: number): (num: number) => number {
    return (num) => this.scaleNumber(num, oldMax, newMax)
  }

  scaleNumber (num: number, oldMax: number, newMax: number): number {
    return num * newMax / oldMax
  }

  setupCanvas (): void {
    this.ranSetUp = true
    const oldWidth = this.originalHeight * (this.imageTarget.width / this.imageTarget.height)
    this.scaleX = this.scaleNumberFactory(oldWidth, this.imageTarget.width)
    this.scaleY = this.scaleNumberFactory(this.originalHeight, this.imageTarget.height)
    this.heatmapInstance = h337.create({ container: this.heatmapTarget, radius: 40, minOpacity: 0, maxOpacity: 0.45 })
    this.canvas = document.querySelector('.heatmap-canvas') as HTMLCanvasElement
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D
  }

  showNewData (): void {
    if (!this.ranSetUp) {
      this.setupCanvas()
    }
    this.update()
  }

  update (): void {
    // Implement in sub class
  }

  setHeatmapData (_: StatisticsImport): void {
    if (this.imageTarget.complete) {
      this.showNewData()
    } else {
      this.imageTarget.addEventListener('load', this.showNewData.bind(this))
    }
  }

  setPoints (min: number, max: number, points: FormattedHeatmapPoint[]): void {
    const scaledPoints = points.map((point) => {
      return {
        x: Math.floor(this.scaleX(point.x)),
        y: Math.floor(this.scaleY(point.y)),
        value: point.value
      }
    })
    this.heatmapInstance.setData({ min, max, data: scaledPoints })
  }

  setSensorIcons (): void {
    const targets = this.pointTargets.filter((point) => point.dataset.targetType === this.targetType || !this.isOrBelongsToExGroup(point))
    targets.forEach((point) => {
      const { y, x, name } = point.dataset
      if (x !== undefined && y !== undefined && name !== undefined) {
        const scaledY = this.scaleY(+y)
        const scaledX = this.scaleX(+x)
        this.drawPoint(scaledX, scaledY, 12, 'rgba(206, 196, 215, 0.8)', '#8366c2')
        this.drawPoint(scaledX, scaledY, 3, '#8366c2')
        this.drawText(name, scaledX, scaledY - 20)
      }
    })
  }

  isOrBelongsToExGroup (point: HTMLElement): boolean {
    const { belongsToExGroup } = point.dataset
    if (belongsToExGroup === undefined) return true
    return belongsToExGroup === 'true'
  }

  drawPoint (x: number, y: number, size: number, colour: string, borderColour: string | null = null): void {
    this.ctx.beginPath()
    this.ctx.arc(x, y, size, 0, ExBaseHeatmapController.FULL_CIRCLE_RADIANS)
    this.ctx.fillStyle = colour
    this.ctx.fill()
    if (borderColour !== null) {
      this.ctx.strokeStyle = borderColour
      this.ctx.lineWidth = 1
      this.ctx.stroke()
    }
    this.ctx.closePath()
  }

  drawText (text: string, x: number, y: number): void {
    this.ctx.fillStyle = '#000'
    this.ctx.font = 'bold 10px Arial'
    this.ctx.textAlign = 'center'
    this.ctx.fillText(text, x, y)
  }

  setMapBackground (): void {
    this.heatmapInstance.addBackground()
  }
}
