import Chart from 'stimulus-chartjs'
import type { Chart as ChartType } from 'chart.js'

// Connects to data-controller="ex-stacked-bar-chart"
export default class extends Chart {
  declare chart: ChartType

  connect (): void {
    this.typeValue = 'bar'
    super.connect()
    if (this.chart.canvas.parentElement !== null) {
      this.chart.canvas.parentElement.style.height = '400px'
    }
  }

  setDwellDistributionStatistics ({ detail }: any): void {
    this.chart.data = detail
    this.chart.update()
  }

  get defaultOptions (): any {
    return {
      interaction: {
        mode: 'index'
      },
      animation: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom'
        },
        tooltip: {
          position: 'nearest',
          itemSort: function (a: any, b: any) {
            return b.datasetIndex - a.datasetIndex
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            display: false
          }
        },
        y: {
          stacked: true
        }
      }
    }
  }
}
