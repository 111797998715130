import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="ex-flow-and-journey"
export default class extends Controller {
  static values = { customerJourneyUrl: String }

  declare readonly customerJourneyUrlValue: string
  declare readonly periodsDropdownTarget: HTMLElement
  declare readonly visitsDropdownTarget: HTMLElement
  declare readonly startTargetDropdownTarget: HTMLElement
  declare currentVisits: HTMLElement
  declare currentPeriod: HTMLElement
  declare currentStartTarget: HTMLElement
  declare readonly selectedVisitsTarget: HTMLElement
  declare readonly selectedPeriodTarget: HTMLElement
  declare readonly selectedStartTargetTarget: HTMLElement
  declare readonly selectedStartTargetTargets: HTMLElement[]
  declare readonly selectedStartTargetsTargets: HTMLElement[]
  declare readonly periodsDropdownButtonTarget: HTMLElement
  declare readonly visitsDropdownButtonTarget: HTMLElement
  declare readonly startTargetDropdownButtonTarget: HTMLElement
  declare periodIndex: string
  declare visits: string
  declare startTargetIds: string
  declare selectedTargetType: string

  static targets = [
    'periodsDropdown', 'periodsDropdownButton', 'selectedPeriod',
    'visitsDropdown', 'visitsDropdownButton', 'selectedVisits',
    'startTargetDropdown', 'startTargetDropdownButton', 'selectedStartTarget',
    'selectedStartTargets'
  ]

  connect (): void {
    this.currentVisits = this.selectedVisitsTarget
    this.currentPeriod = this.selectedPeriodTarget
    this.currentStartTarget = this.selectedStartTargetTarget
    this.periodIndex = '0'
    this.visits = 'engaged_visits_count'
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.startTargetIds = this.currentStartTarget.dataset.startTargetIds!
    this.selectedTargetType = 'ExAllocation'
  }

  setPeriod ({ target }: any): void {
    const { periodIndex } = target.dataset
    this.currentPeriod.classList.remove('eventFlowchart-select-dropdownItemSelected')
    target.classList.add('eventFlowchart-select-dropdownItemSelected')
    this.currentPeriod = target
    this.periodIndex = periodIndex
    this.periodsDropdownButtonTarget.innerHTML = target.innerHTML
    this.togglePeriodsDropdown()
    this.setStatistics()
  }

  setTargetType ({ detail }: any): void {
    this.selectedTargetType = detail.selectedTargetType

    if (this.selectedTargetType === this.currentStartTarget.dataset.targetType) {
      this.startTargetDropdownButtonTarget.innerHTML = this.currentStartTarget.innerHTML
    } else {
      this.startTargetDropdownButtonTarget.innerHTML = '-'
      this.selectedStartTargetTargets.filter(selectedStartTarget => selectedStartTarget.dataset.targetType !== this.currentStartTarget.dataset.targetType)[0].classList.add('eventFlowchart-select-dropdownItemSelected')
    }

    this.selectedStartTargetsTargets.forEach(selectedStartTargets => selectedStartTargets.classList.toggle('hiddenSelectedStartTargets'))
  }

  async setStartTarget ({ target }: any): Promise<void> {
    const { startTargetIds } = target.dataset
    this.selectedStartTargetTargets.forEach(selectedStartTarget => {
      selectedStartTarget.classList.remove('eventFlowchart-select-dropdownItemSelected')
    })
    target.classList.add('eventFlowchart-select-dropdownItemSelected')
    this.currentStartTarget = target
    this.startTargetIds = startTargetIds
    this.toggleStartTargetDropdown()
    if (this.startTargetDropdownButtonTarget !== null) {
      this.startTargetDropdownButtonTarget.innerHTML = 'Please wait..'
      this.startTargetDropdownButtonTarget.parentElement?.classList.add('alertOutline')
    }
    await this.replaceCustomerJourneyData()
    if (this.startTargetDropdownButtonTarget !== null) {
      this.startTargetDropdownButtonTarget.innerHTML = target.innerHTML
      this.startTargetDropdownButtonTarget.parentElement?.classList.remove('alertOutline')
    }
    this.setStatistics()
  }

  async replaceCustomerJourneyData (): Promise<void> {
    const response = await fetch(this.urlWithParams(), {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })

    if (response.ok) {
      const json = await response.json()
      document.querySelector('div[data-controller="ex-customer-journey"]')?.setAttribute('data-ex-customer-journey-data-value', json.data)
    }
  }

  urlWithParams (): string {
    return this.customerJourneyUrlValue + `?start_target_ids=${this.startTargetIds}&start_target_type=${this.selectedTargetType}`
  }

  setStatistics (): void {
    this.dispatch('setCustomerJourneyStatistics', { detail: { periodIndex: this.periodIndex, visits: this.visits } })
    this.dispatch('setFlowStatistics', { detail: { periodIndex: this.periodIndex, visits: this.visits } })
  }

  setVisits ({ target }: any): void {
    const { visits } = target.dataset
    this.currentVisits.classList.remove('eventFlowchart-select-dropdownItemSelected')
    target.classList.add('eventFlowchart-select-dropdownItemSelected')
    this.currentVisits = target
    this.visits = visits
    this.visitsDropdownButtonTarget.innerHTML = target.innerHTML
    this.toggleVisitsDropdown()
    this.setStatistics()
  }

  togglePeriodsDropdown (): void {
    this.periodsDropdownTarget.hidden = !this.periodsDropdownTarget.hidden
  }

  toggleVisitsDropdown (): void {
    this.visitsDropdownTarget.hidden = !this.visitsDropdownTarget.hidden
  }

  toggleStartTargetDropdown (): void {
    this.startTargetDropdownTarget.hidden = !this.startTargetDropdownTarget.hidden
  }
}
