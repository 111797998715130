import BarChartBase from './bar_chart_base'

// Connects to data-controller="ex-bar-chart"
export default class extends BarChartBase {
  connect (): void {
    super.connect()
    if (this.chart.canvas.parentElement !== null) {
      this.chart.canvas.parentElement.style.height = '400px'
    }
  }

  setDwellStatistics ({ detail }: any): void {
    this.chart.data = detail
    this.chart.update()
  }

  get defaultOptions (): any {
    return {
      animation: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: this.tooltip()
      },
      scales: this.scales()
    }
  }
}
