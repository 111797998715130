import { Controller } from '@hotwired/stimulus'
import type { HTMLInputEvent } from '../types/html_event'
import type { SendorSceneInfo, Point, Config, Image } from '../types/sensor_scene_info'

export default class extends Controller {
  static targets = ['canvas']
  static values = { logicsInfo: Object }

  logicsInfoValue!: SendorSceneInfo
  readonly canvasTarget!: HTMLCanvasElement

  declare image: Image
  declare canvasWidth: number
  declare canvasHeight: number
  declare convertX: (oldValue: number) => number
  declare convertY: (oldValue: number) => number
  declare setZoneLogic: (logicName: string, visible: boolean) => void
  declare setLineLogic: (logicName: string, visible: boolean) => void
  declare ctx: CanvasRenderingContext2D

  connect (): void {
    this.image = this.logicsInfoValue.image
    this.canvasWidth = this.image.width > 900 ? 900 : this.image.width
    const convertHeight = this.convertFactory(0, this.image.width, 0, this.canvasWidth)
    this.canvasHeight = convertHeight(this.image.height)
    this.convertX = this.convertFactory(5.5, -5.5, this.canvasWidth, 0)
    this.convertY = this.convertFactory(3.5, -3.5, 0, this.canvasHeight)
    this.setZoneLogic = this.setLogicFactory('zone')
    this.setLineLogic = this.setLogicFactory('line')
    this.setUpCanvas()
    this.draw()
  }

  setUpCanvas (): void {
    this.canvasTarget.width = this.canvasWidth
    this.canvasTarget.height = this.canvasHeight
    this.canvasTarget.style.backgroundSize = 'cover'
    this.canvasTarget.style.backgroundImage = `url('${this.image.url}')`
    this.ctx = this.canvasTarget.getContext('2d') as CanvasRenderingContext2D
    this.ctx.lineWidth = 5
  }

  setLogicHandler (event: HTMLInputEvent): void {
    const type = event.target.name
    const logicName = event.target.id
    const visible = event.target.checked
    const setLogic = type === 'line' ? this.setLineLogic : this.setZoneLogic
    setLogic(logicName, visible)
    this.draw()
  }

  draw (): void {
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    this.drawLines()
    this.drawZones()
  }

  drawLines (): void {
    this.logicsInfoValue.lines.forEach((lineConfig) => {
      if (!lineConfig.visible) return
      if (typeof lineConfig.geometry !== 'string') return
      const parsedLine = JSON.parse(lineConfig.geometry)
      let lastPoint = this.point(parsedLine[0])
      parsedLine.forEach((pointConfig: number[]) => {
        const endPoint = this.point(pointConfig)
        this.drawCanvasLine(lastPoint, endPoint, lineConfig.colour)
        lastPoint = endPoint
      })
    })
  }

  drawZones (): void {
    this.logicsInfoValue.zones.forEach((zoneConfig) => {
      if (!zoneConfig.visible) return
      if (typeof zoneConfig.geometry !== 'string') return
      const parsedLine = JSON.parse(zoneConfig.geometry)
      const firstPoint = this.point(parsedLine[0])
      let lastPoint = firstPoint
      parsedLine.forEach((pointConfig: number[]) => {
        const endPoint = this.point(pointConfig)
        this.drawCanvasLine(lastPoint, endPoint, zoneConfig.colour)
        lastPoint = endPoint
      })
      this.drawCanvasLine(lastPoint, firstPoint, zoneConfig.colour)
    })
  }

  drawCanvasLine (stPoint: Point, endPoint: Point, color: string): void {
    this.ctx.beginPath()
    this.ctx.moveTo(stPoint.x, stPoint.y)
    this.ctx.lineTo(endPoint.x, endPoint.y)
    this.ctx.strokeStyle = color
    this.ctx.stroke()
    this.ctx.closePath()
  }

  point (pointConfig: number[]): Point {
    const [x, y] = pointConfig
    return { x: this.convertX(x), y: this.convertY(y) }
  }

  // To convert Apex X/Y values to canvas coordinates
  convertFactory (oldMax: number, oldMin: number, newMax: number, newMin: number): (oldValue: number) => number {
    const oldRange = (oldMax - oldMin)
    const newRange = (newMax - newMin)
    return function (oldValue: number) {
      return (((oldValue - oldMin) * newRange) / oldRange) + newMin
    }
  }

  // To set the visibility of a line or zone
  setLogicFactory (type: string): (logicName: string, visible: boolean) => void {
    const [configs, setConfig] = type === 'line' ? this.lineConfigGetterSetter() : this.zoneConfigGetterSetter()
    return function (logicName, visible) {
      configs.find((o: Config) => o.name === logicName).visible = visible
      setConfig(configs)
    }
  }

  setZoneConfigsValue (newZoneConfig: Config[]): void {
    const current = this.logicsInfoValue
    current.zones = newZoneConfig
    this.logicsInfoValue = current
  }

  setLineConfigsValue (newLineConfig: Config[]): void {
    const current = this.logicsInfoValue
    current.lines = newLineConfig
    this.logicsInfoValue = current
  }

  zoneConfigGetterSetter (): any[] {
    return [this.logicsInfoValue.zones, this.setZoneConfigsValue.bind(this)]
  }

  lineConfigGetterSetter (): any[] {
    return [this.logicsInfoValue.lines, this.setLineConfigsValue.bind(this)]
  }
}
