import LineChartBase from './line_chart_base'

// Connects to data-controller="average-booth-score-chart"
export default class extends LineChartBase {
  scales (): any {
    return {
      y: { beginAtZero: true, suggestedMax: 5 },
      x: { display: true, parsing: false, type: 'category' }
    }
  }
}
