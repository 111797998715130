import { Controller } from '@hotwired/stimulus'
import { type HTMLEvent } from '../types/html_event'

// Connects to data-controller="column-sort"
export default class extends Controller {
  static targets = ['table']

  declare readonly tableTarget: HTMLTableElement
  declare tableId: string

  connect (): void {
    this.tableId = this.tableTarget.dataset.tableId ?? ''
    document.addEventListener('turbo:submit-end', ({ detail: { success } }: any): void => {
      if (success === true) {
        const filterInput = document.querySelector<HTMLInputElement>('input[name="filter"]')

        if (filterInput === null) return

        const searchValue = filterInput.value

        const newUrl = new URL(window.location.href)

        if (searchValue.trim() === '') {
          newUrl.searchParams.delete('filter')
        } else {
          newUrl.searchParams.set('filter', searchValue)
        }

        // Update the URL without reloading the page
        history.pushState({}, '', newUrl)
      }
    })
  }

  sort (event: HTMLEvent): void {
    if (this.tableId === '') return
    const { currentTarget } = event
    const { sort } = currentTarget.dataset
    const sortColumnKey = `sort[${this.tableId}][column]`
    const sortReverseKey = `sort[${this.tableId}][reverse]`
    const currentUrl = new URL(window.location.href)
    const params = currentUrl.searchParams

    const filterInput = document.querySelector<HTMLInputElement>('input[name="filter"]')

    if (filterInput !== null) {
      const searchValue = filterInput.value
      if (searchValue.trim() === '') {
        currentUrl.searchParams.delete('filter')
      } else {
        currentUrl.searchParams.set('filter', searchValue)
      }
    }

    const currentSortReverse = params.get(sortReverseKey)
    if (currentSortReverse === 'true') {
      params.set(sortReverseKey, 'false')
    } else {
      params.set(sortReverseKey, 'true')
    }
    params.set(sortColumnKey, sort ?? '')
    const updatedUrl = currentUrl.pathname + '?' + params.toString()

    window.location.href = updatedUrl
  }
}
