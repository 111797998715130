import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="devices"
export default class extends Controller {
  static targets = ['devicesTableContainer', 'dataLastSeenDialogLoadingSpinner']

  declare readonly devicesTableContainerTarget: HTMLElement
  declare readonly dataLastSeenDialogLoadingSpinnerTarget: HTMLElement

  displaySpinner (): void {
    this.dataLastSeenDialogLoadingSpinnerTarget.style.display = 'flex'
    this.devicesTableContainerTarget.style.display = 'none'
  }
}
