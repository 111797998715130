import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['searchBox', 'element']

  declare readonly searchBoxTarget: HTMLInputElement
  declare readonly elementTargets: HTMLElement[]

  connect (): void {
    this.searchBoxTarget.addEventListener('input', () => { this.filterElements() })
  }

  filterElements (): void {
    const searchValue = this.searchBoxTarget.value.toLowerCase()
    this.elementTargets.forEach((elementTarget) => {
      const searchLabel = elementTarget.dataset.searchLabel!.toLowerCase()
      if (searchLabel.includes(searchValue)) {
        elementTarget.style.removeProperty('display')
      } else {
        elementTarget.style.display = 'none'
      }
    })
  }
}
