import { Controller } from '@hotwired/stimulus'
import type { HTMLInputEvent } from '../types/html_event'
import type { State } from '../types/location'

// Connects to data-controller="location"
export default class extends Controller {
  static targets = ['container', 'locationName', 'welcomeMessage', 'occupancyCount', 'currentCapacity', 'capacityPercent']
  static values = { event: String, location: String, locationname: String, maxcapacity: Number }

  eventValue!: string
  locationValue!: string
  locationnameValue!: string
  maxcapacityValue!: number

  declare readonly containerTarget: HTMLElement
  declare readonly locationNameTarget: HTMLElement
  declare readonly welcomeMessageTarget: HTMLElement
  declare readonly occupancyCountTarget: HTMLElement
  declare readonly currentCapacityTarget: HTMLElement
  declare readonly capacityPercentTarget: HTMLElement

  declare state: State

  currentCapacity: number = 0
  connected: boolean = false

  async connect (): Promise<void> {
    this.state = {
      welcomeUnder: 'Enter',
      welcomeOver: 'Stop',
      colourUnder: '#008000',
      colourOver: '#FF0000',
      fontSize: 'font-medium',
      displayLocation: 'hidden',
      displayWelcome: 'visible',
      displayOccupancy: 'hidden',
      displayCapacity: 'hidden',
      error: false,
      errorMessage: ''
    }

    this.connected = true
    this.updateInterface()
    await this.update()
  }

  disconnect (): void {
    this.connected = false
  }

  async getResponse (url: string): Promise<Response> {
    return await fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
  }

  setState (data: object): void {
    this.state = Object.assign(this.state, data)
    this.updateInterface()
  }

  async updateCount (): Promise<void> {
    if (document.hidden) return
    const response = await this.getResponse(`/events/${this.eventValue}/locations/${this.locationValue}/live_line_counts/`)
    const json = await response.json()
    const { counts } = json
    this.currentCapacity = parseInt(counts)
  }

  async update (): Promise<void> {
    try {
      await this.updateCount()
      this.setState({ error: false })
    } catch (err) {
      this.setState({ error: true, errorMessage: err })
    }
    if (this.connected) setTimeout(this.update.bind(this), 2000)
  }

  async toggleFullscreen (): Promise<void> {
    if (document.fullscreenElement !== null) {
      await document.exitFullscreen()
    } else {
      await this.containerTarget.requestFullscreen()
    }
  }

  updateInterface (): void {
    if (this.state.error) {
      this.locationNameTarget.style.visibility = 'visible'
      this.locationNameTarget.innerHTML = this.state.errorMessage
      this.welcomeMessageTarget.style.visibility = 'visible'
      this.welcomeMessageTarget.innerHTML = 'Error'
      this.containerTarget.style.backgroundColor = 'grey'
    } else {
      this.locationNameTarget.style.visibility = this.state.displayLocation
      this.welcomeMessageTarget.style.visibility = this.state.displayWelcome
      this.occupancyCountTarget.style.visibility = this.state.displayOccupancy
      this.currentCapacityTarget.style.visibility = this.state.displayCapacity

      if (this.state.displayCapacity === 'visible') {
        const capacityPercent = Math.round((this.currentCapacity / this.maxcapacityValue) * 100)
        this.capacityPercentTarget.innerHTML = `${capacityPercent}%`
      }

      if (this.state.displayOccupancy === 'visible') {
        this.occupancyCountTarget.innerHTML = `Occupancy ${this.currentCapacity}`
      }

      const isUnderCapacity = this.currentCapacity < this.maxcapacityValue
      if (this.state.displayWelcome === 'visible') this.welcomeMessageTarget.innerHTML = isUnderCapacity ? this.state.welcomeUnder : this.state.welcomeOver
      this.containerTarget.style.backgroundColor = isUnderCapacity ? this.state.colourUnder : this.state.colourOver
      if (this.state.displayLocation === 'visible') this.locationNameTarget.innerHTML = this.locationnameValue

      switch (this.state.fontSize) {
        case 'font-small':
          this.updateFontSizeClasses('text-xl', ['text-2xl', 'text-4xl'], 'text-6xl', ['text-8xl', 'text-9xl'])
          break

        case 'font-medium':
          this.updateFontSizeClasses('text-2xl', ['text-xl', 'text-4xl'], 'text-8xl', ['text-6xl', 'text-9xl'])
          break

        case 'font-large':
          this.updateFontSizeClasses('text-4xl', ['text-xl', 'text-2xl'], 'text-9xl', ['text-6xl', 'text-8xl'])
          break
      }
    }
  }

  updateFontSizeClasses (classToAddSM: string, classesToRemoveSM: string[], classToAddLG: string, classesToRemoveLG: string[]): void {
    [this.locationNameTarget, this.occupancyCountTarget, this.currentCapacityTarget].forEach((elm) => {
      elm.classList.add(classToAddSM)
      elm.classList.remove(...classesToRemoveSM)
    })

    this.welcomeMessageTarget.classList.add(classToAddLG)
    this.welcomeMessageTarget.classList.remove(...classesToRemoveLG)
  }

  toggleElement (e: HTMLInputEvent): void {
    const checked: boolean = e.target.checked
    const key = e.target.id
    this.setState({ [key]: checked ? 'visible' : 'hidden' })
  }

  updateValue (e: HTMLInputEvent): void {
    const newValue: string = e.target.value
    const key = e.target.id
    this.setState({ [key]: newValue })
  }

  changeFontSize (e: HTMLInputEvent): void {
    const size: string = e.target.id
    this.setState({ fontSize: size })
  }
}
