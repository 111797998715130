import type { Point } from '../types/flowchart'

export class Sensor {
  declare _location: Point
  declare _name: string

  constructor (name: string, location: Point) {
    this._name = name
    this._location = location
  }

  get location (): Point {
    return this._location
  }

  get name (): string {
    return this._name
  }
}
