import { Controller } from '@hotwired/stimulus'
import Chart from '../chart-js-gauge'

// Connects to data-controller="ex-kpi-gauge"
export default class extends Controller {
  static values = { data: Object }
  static targets = ['canvas']

  connect () {
    const ctx = this.canvasTarget.getContext('2d')
    // eslint-disable-next-line no-new
    this.chart = new Chart(ctx, {
      type: 'gauge',
      data: {
        datasets: [{
          value: this.dataValue.kpi_score,
          data: [33, 33, 33],
          backgroundColor: ['#e90100', '#ffd500', '#00c881'],
          borderWidth: 2
        }]
      },
      options: {
        plugins: {
          tooltip: {
            enabled: false
          }
        },
        responsive: true,
        title: {
          display: false
        },
        layout: {
          padding: {
            bottom: 0
          }
        },
        needle: {
          radiusPercentage: 2,
          widthPercentage: 2.2,
          lengthPercentage: 80,
          color: 'rgba(0, 0, 0, 1)'
        },
        valueLabel: {
          display: true,
          formatter: null,
          color: 'rgba(255, 255, 255, 1)',
          backgroundColor: 'rgba(0, 0, 0, 1)',
          borderRadius: 5,
          padding: {
            top: 5,
            right: 5,
            bottom: 5,
            left: 5
          },
          bottomMarginPercentage: 0
        },
        cutoutPercentage: 50,
        rotation: -90,
        circumference: 180,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      }
    })
  }
}
