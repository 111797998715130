import { Controller } from '@hotwired/stimulus'
import { type HTMLEvent } from '../types/html_event'

export default class extends Controller {
  static targets = [
    'periodDropdown', 'periodDropdownButton', 'selectedPeriod',
    'sensorsChartButton', 'sensorGroupsChartButton',
    'dwellChartButton', 'visitorsChartButton'
  ]

  static values = {
    statistics: Object,
    eventDayEndsAt: Number,
    eventDayStartsAt: Number
  }

  declare readonly dwellChartButtonTarget: HTMLElement
  declare readonly visitorsChartButtonTarget: HTMLElement
  declare readonly sensorsChartButtonTarget: HTMLElement
  declare readonly sensorGroupsChartButtonTarget: HTMLElement
  declare readonly periodDropdownTarget: HTMLElement
  declare readonly periodDropdownButtonTarget: HTMLElement
  declare readonly selectedPeriodTarget: HTMLElement
  declare readonly statisticsValue: any

  declare selectedChart: string
  declare selectedChartType: string
  declare eventDayEndsAtValue: number
  declare eventDayStartsAtValue: number
  declare currentPeriod: HTMLElement
  declare eventDayIndex: number

  connect (): void {
    this.currentPeriod = this.selectedPeriodTarget
    this.eventDayIndex = 1
    this.selectedChart = 'sensors'
    this.selectedChartType = 'visitors'
    setTimeout(() => { this.setComparisonStatistics() }, 1)
  }

  setPeriod ({ target }: any): void {
    const { eventDayIndex, eventDayStartsAt, eventDayEndsAt } = target.dataset
    if (eventDayIndex !== this.eventDayIndex) {
      this.currentPeriod.classList.remove('ex-statistics-dropdownItem-selected')
      target.classList.add('ex-statistics-dropdownItem-selected')
      this.currentPeriod = target
      this.eventDayIndex = Number(eventDayIndex)
      this.eventDayStartsAtValue = eventDayStartsAt
      this.eventDayEndsAtValue = eventDayEndsAt
      this.periodDropdownButtonTarget.innerHTML = target.innerHTML
      this.setComparisonStatistics()
    }
    this.periodDropdownTarget.hidden = true
  }

  setComparisonStatistics (event: HTMLEvent | null = null): void {
    if (event !== null) {
      const dataset = event.currentTarget.dataset
      if (dataset.chart) this.selectedChart = dataset.chart
      if (dataset.chartType) this.selectedChartType = dataset.chartType
    }

    const statistics = this.statisticsValue
    if (this.eventDayIndex !== 0) {
      statistics.bar_chart.labels = [statistics.bar_chart.labels[this.eventDayIndex - 1]]

      statistics.bar_chart.datasets.forEach((vs: any) => {
        vs.data = [vs.data[this.eventDayIndex - 1]]
      })

      statistics.line_chart.datasets.forEach((vs: any) => {
        vs.data = vs.data.filter(({ time }: { time: number }) => time >= this.eventDayStartsAtValue && time <= this.eventDayEndsAtValue)
      })

      if (this.hasGroupStatisitcs()) {
        statistics.group_bar_chart.labels = [statistics.group_bar_chart.labels[this.eventDayIndex - 1]]
        statistics.group_bar_chart.datasets.forEach((vs: any) => {
          vs.data = [vs.data[this.eventDayIndex - 1]]
        })
        statistics.group_line_chart.datasets.forEach((vs: any) => {
          vs.data = vs.data.filter(({ time }: { time: number }) => time >= this.eventDayStartsAtValue && time <= this.eventDayEndsAtValue)
        })
      }
    }
    let lineChartStatistics = statistics.line_chart
    let barChartStatistics = statistics.bar_chart

    if (this.selectedChart === 'groups') {
      lineChartStatistics = statistics.group_line_chart
      barChartStatistics = statistics.group_bar_chart
    }

    this.updateView()
    this.dispatch('setComparisonBarChartStatistics', { detail: { statistics: barChartStatistics, selection: { chart: this.selectedChart, chartType: this.selectedChartType } } })
    this.dispatch('setComparisonLineChartStatistics', { detail: { statistics: lineChartStatistics, selection: { chart: this.selectedChart, chartType: this.selectedChartType } } })
  }

  hasGroupStatisitcs (): boolean {
    return !!this.statisticsValue.group_bar_chart
  }

  updateView (): void {
    this.periodDropdownTarget.hidden = true
    const selectedClass = 'ex-statistics-chart-selected'
    if (this.hasGroupStatisitcs()) {
      if (this.selectedChart === 'groups') {
        this.sensorGroupsChartButtonTarget.classList.add(selectedClass)
        this.sensorsChartButtonTarget.classList.remove(selectedClass)
      } else if (this.selectedChart === 'sensors') {
        this.sensorsChartButtonTarget.classList.add(selectedClass)
        this.sensorGroupsChartButtonTarget.classList.remove(selectedClass)
      }
    }

    if (this.selectedChartType === 'visitors') {
      this.visitorsChartButtonTarget.classList.add(selectedClass)
      this.dwellChartButtonTarget.classList.remove(selectedClass)
    } else if (this.selectedChartType === 'dwell') {
      this.dwellChartButtonTarget.classList.add(selectedClass)
      this.visitorsChartButtonTarget.classList.remove(selectedClass)
    }
  }

  togglePeriodDropdown (): void {
    this.periodDropdownTarget.hidden = !this.periodDropdownTarget.hidden
  }
}
