import Chart from 'stimulus-chartjs'
import type { Chart as ChartType } from 'chart.js'

// Connects to data-controller="dashboard-chart"
export default class extends Chart {
  declare chart: ChartType
  static values = {
    chartFullSize: Boolean,
    chartType: String,
    chartMetric: String
  }

  configOptions = {
    booth_score: {
      step: 1,
      max: 5,
      title: (context: any) => {
        return String(context[0].raw)
      },
      yTicks: (context: any) => {
        return `${String(context)}.0`
      }
    },
    percent: {
      step: 25,
      max: 100,
      title: (context: any) => {
        return `${String(context[0].raw)}%`
      },
      yTicks: (context: any) => {
        return `${String(context)}%`
      }
    },
    time: {
      step: 60,
      max: undefined,
      title: (context: any) => {
        const seconds = context[0].raw % 60
        const minutes = Math.floor((context[0].raw - seconds) / 60)
        return `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`
      },
      yTicks: (context: any) => {
        return `${String(Math.floor(context / 60)).padStart(2, '0')}m`
      }
    },
    displayAxes (chartTypeValue: string): boolean {
      return chartTypeValue === 'bar'
    }
  }

  connect (): void {
    this.typeValue = this.chartTypeValue
    this.config = this.configOptions[this.chartMetricValue as keyof typeof this.configOptions]
    super.connect()
  }

  displayAxes (): boolean {
    return this.chartTypeValue === 'bar'
  }

  displayLegend (): boolean {
    return this.chartTypeValue !== 'bar' && this.chartFullSizeValue
  }

  displayXTicks (): boolean {
    return this.chartTypeValue === 'bar' && this.chartFullSizeValue
  }

  get defaultOptions (): any {
    return {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: this.displayLegend()
        },
        tooltip: {
          callbacks: {
            title: (context: any) => this.config.title(context),
            label: (context: { dataset: { label: string }, label: string }) => context.label
          }
        }
      },
      scales: {
        y: {
          max: this.config.max,
          display: this.displayAxes(),
          ticks: {
            stepSize: this.config.step,
            callback: (context: any) => this.config.yTicks(context)
          }
        },
        x: {
          display: this.displayAxes(),
          ticks: {
            display: this.displayXTicks()
          }
        }
      }
    }
  }
}
