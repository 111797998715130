import ExBaseHeatmapController from './ex_base_heatmap_controller'
import { type StatisticsImport } from '../types/map_statistics'

// Connects to data-controller="ex-realtime-heatmap"
export default class extends ExBaseHeatmapController {
  update (): void {
    const data = this.heatmapData[0]
    if (data === undefined) return
    this.setPoints(data.min, data.max, data.points)
    this.setSensorIcons()
    this.setMapBackground()
  }

  setHeatmapData (data: StatisticsImport): void {
    this.heatmapData = data.detail
    super.setHeatmapData(data)
  }
}
