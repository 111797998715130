import BarChartBase from './bar_chart_base'

// Connects to data-controller="ex-comparison-bar-chart"
export default class extends BarChartBase {
  static targets = ['container']

  setComparisonBarChartStatistics ({ detail: { statistics, selection: { chart, chartType } } }: any): void {
    if (chartType !== 'dwell') {
      this.containerTarget.hidden = true
    } else if (chartType === 'dwell') {
      this.containerTarget.hidden = false
    }
    this.chart.legend!.options.title.text = `${chart.charAt(0).toUpperCase()}${chart.slice(1)}`
    this.chart.data = statistics
    this.chart.update()
  }

  get defaultOptions (): any {
    return {
      animation: false,
      pointRadius: 5,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          align: 'start',
          position: 'right',
          title: {
            display: true,
            font: { size: 16 },
            text: 'Sensors'
          },
          labels: {
            borderRadius: 7,
            boxHeight: 15,
            boxWidth: 15,
            padding: 12,
            useBorderRadius: true
          }
        },
        tooltip: this.tooltip()
      },
      scales: this.scales()
    }
  }
}
