import DialogController from './dialog_controller'

// Connects to data-controller="events-search"
export default class extends DialogController {
  static targets = ['input', 'submit']
  static classes = ['disabled']

  declare readonly inputTarget: HTMLInputElement
  declare readonly submitTarget: HTMLButtonElement

  declare readonly disabledClass: string

  connect (): void {
    this.dialogTarget.addEventListener('close', () => {
      document.body.style.overflow = 'auto'
    })
  }

  openDialog (): void {
    document.body.style.overflow = 'hidden'
    super.openDialog()
  }

  handleInputChange (): void {
    if (this.inputTarget.value.length < 3) {
      this.submitTarget.classList.add(this.disabledClass)
    } else {
      this.submitTarget.classList.remove(this.disabledClass)
    }
  }
}
