import { Controller } from '@hotwired/stimulus'
import { LocalStorageManager } from '../helpers/local_storage_manager'

// Connects to data-controller="table-resizer"
export default class extends Controller {
  static targets = ['table']

  declare readonly tableTarget: HTMLTableElement
  declare tableId: string
  declare localStorageManager: LocalStorageManager

  connect (): void {
    this.tableId = this.tableTarget.dataset.tableId ?? ''
    this.localStorageManager = new LocalStorageManager()
    this.addColumnIds()
    this.applyTableSettings()
    this.makeTableResizable()
  }

  makeTableResizable (): void {
    const columns = this.tableTarget.querySelectorAll('th')
    columns.forEach((column) => {
      const resizer = document.createElement('div')
      resizer.classList.add('resizableTable-resizer')
      resizer.style.height = `${this.tableTarget.offsetHeight}px`
      column.appendChild(resizer)
      this.makeColumnResizable(column, resizer)
    })
  }

  makeColumnResizable (column: HTMLElement, resizer: HTMLElement): void {
    const saveTableSettings = this.saveTableSettings.bind(this)

    function mouseDownHandler (event: MouseEvent): void {
      x = event.clientX

      const columnStyles = window.getComputedStyle(column)

      originalWidth = parseInt(columnStyles.width)

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)

      resizer.classList.add('resizableTable-resizing')
      column.classList.add('pointerEvents-none')
    }

    function mouseMoveHandler (e: MouseEvent): void {
      const dx = e.clientX - x
      column.style.width = `${originalWidth + dx}px`
      saveTableSettings()
    }

    function mouseUpHandler (): void {
      resizer.classList.remove('resizableTable-resizing')
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
      column.classList.remove('pointerEvents-none')
    }

    let x = 0
    let originalWidth = 0

    resizer.addEventListener('mousedown', mouseDownHandler)
  }

  addColumnIds (): void {
    const columns = this.tableTarget.querySelectorAll('th')

    columns.forEach((column, index) => {
      column.setAttribute('data-columnid', index.toString())
    })
  }

  applyTableSettings (): void {
    if (this.tableId === '') return

    const columns = this.tableTarget.querySelectorAll('th')

    columns.forEach((column) => {
      const columnID = column.dataset.columnid
      if (columnID !== undefined) {
        const width = this.localStorageManager.getItem('ui_settings', 'tables', this.tableId, 'columns', columnID, 'width')
        if (typeof width === 'number') {
          column.style.width = `${width}px`
        }
      }
    })
  }

  saveTableSettings (): void {
    if (this.tableId === '') return

    const columns = this.tableTarget.querySelectorAll('th')

    columns.forEach((column) => {
      const columnID = column.dataset.columnid
      if (columnID !== undefined) {
        this.localStorageManager.setItem(column.offsetWidth, 'ui_settings', 'tables', this.tableId, 'columns', columnID, 'width')
      }
    })
  }
}
