import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

// Connects to data-controller="slim-select"
export default class extends Controller {
  static targets = ['container']

  declare readonly containerTarget: HTMLElement

  declare mainSlimElement: HTMLElement
  declare slimDropdownElement: HTMLElement
  declare slimSelect: SlimSelect
  declare multiple: boolean

  connect (): void {
    this._setupSlim()
    this._hideUnusedSlimElements()
    this._addDropdownEventListenerToElements()
  }

  _setupSlim (): void {
    const selectElement = this.element.querySelector('select') as HTMLSelectElement
    this.multiple = selectElement.multiple
    this.slimSelect = new SlimSelect({
      select: selectElement,
      settings: {
        closeOnSelect: true, // Should close the dropdown element but can't get this to work so rolling my own implementation
        contentLocation: this.containerTarget,
        contentPosition: 'relative',
        openPosition: 'down',
        alwaysOpen: this.multiple,
        allowDeselect: this.multiple
      },
      events: {
        beforeOpen: () => {
          if (this.multiple) { return }

          const mainSelect = this.element.querySelector('div.ss-main') as HTMLElement
          mainSelect.classList.add('display-none')

          const searchInput = this.element.querySelector('div.ss-search > input') as HTMLInputElement
          searchInput.value = mainSelect.innerText
          searchInput.focus()
        },
        beforeClose: () => {
          if (this.multiple) { return }

          const mainSelect = this.element.querySelector('div.ss-main') as HTMLElement
          mainSelect.classList.remove('display-none')

          this.slimDropdownElement.classList.add('display-none')
        }
      }
    })
    this.mainSlimElement = this.element.querySelector('div.ss-main') as HTMLElement
    this.slimDropdownElement = this.element.querySelector('div.ss-content') as HTMLElement

    if (this.multiple) {
      this.slimDropdownElement.classList.add('ss-content-multiple')
      this.mainSlimElement.classList.add('display-none')
    } else {
      this._toggleDropdown() // Not a multi select, so hide the dropdown on page load
    }
  }

  _hideUnusedSlimElements (): void {
    const svgOne = this.element.querySelector('.ss-deselect.ss-hide') as HTMLElement
    const svgTwo = this.element.querySelector('.ss-arrow') as HTMLElement
    this._hideUnusedSlimElement(svgOne)
    this._hideUnusedSlimElement(svgTwo)
  }

  _hideUnusedSlimElement (element: HTMLElement): void {
    if (element !== undefined && element !== null) element.style.display = 'none'
  }

  _addDropdownEventListenerToElements (): void {
    this._addDropdownEventListenerToElement(this.mainSlimElement)
    const slimOptionElements = this.element.querySelectorAll('.ss-content .ss-option')
    slimOptionElements.forEach((slimOptionElement) => { this._addDropdownEventListenerToElement(slimOptionElement as HTMLElement) })
  }

  _addDropdownEventListenerToElement (element: HTMLElement): void {
    const { appliedDropdownEventListener } = element.dataset
    if (appliedDropdownEventListener === undefined) {
      // Slim recreates the option elements when clicked - the event listeners needs to be reapplied to the newly created option elements
      element.addEventListener('click', () => { this._toggleDropdown(); this._addDropdownEventListenerToElements() })
      element.dataset.appliedDropdownEventListener = 'true'
    }
  }

  _toggleDropdown (): void {
    if (this.multiple) { return }

    this.slimDropdownElement.classList.toggle('display-none')

    if (this.slimDropdownElement.classList.contains('display-none')) {
      this.slimSelect.close()
    }
  }
}
