import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="event-comparison-ordering"
export default class extends Controller {
  static targets = [
    'dropdown', 'dropdownButton', 'selectedOption'
  ]

  declare readonly dropdownTarget: HTMLElement
  declare readonly dropdownButtonTarget: HTMLElement
  declare readonly selectedOptionTarget: HTMLElement

  declare currentSelectedOption: HTMLElement
  declare order: string | undefined

  connect (): void {
    this.currentSelectedOption = this.selectedOptionTarget
    this.order = this.selectedOptionTarget.dataset.order
  }

  toggleDropdown (): void {
    this.dropdownTarget.hidden = !this.dropdownTarget.hidden
  }

  setOption ({ target }: any): void {
    const { order } = target.dataset
    if (order !== this.order) {
      const currentUrl = new URL(window.location.href)
      const params = currentUrl.searchParams
      params.set('order', order)
      const updatedUrl = currentUrl.pathname + '?' + params.toString()
      window.location.href = updatedUrl
    }
  }
}
