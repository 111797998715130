import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dropdown', 'hour', 'frequent', 'selected']
  declare currentPeriod: HTMLElement
  declare selectedTarget: HTMLElement
  declare readonly dropdownTarget: HTMLElement
  declare readonly hourTarget: HTMLElement
  declare readonly frequentTarget: HTMLElement

  connect (): void {
    this.currentPeriod = this.hourTarget
  }

  toggleDropdown (): void {
    this.dropdownTarget.hidden = !this.dropdownTarget.hidden
  }

  setPeriod ({ target }: any): void {
    if (target !== this.currentPeriod) {
      const cssClasses = ['bg-sky-600', 'text-white']
      this.currentPeriod = target
      const el = [this.hourTarget, this.frequentTarget].find((t: HTMLElement) => t !== target)
      el?.classList.remove(...cssClasses)
      target.classList.add(...cssClasses)
      this.selectedTarget.innerHTML = target.innerHTML
      let period
      if (target === this.hourTarget) period = 'hour'
      if (target === this.frequentTarget) period = 'frequent'
      this.dispatch('setPeriod', { detail: { period } })
    }
    this.toggleDropdown()
  }
}
