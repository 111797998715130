import { Controller } from '@hotwired/stimulus'
import type { Data, Point } from '../types/flowchart'
import { Sensor } from '../flowchart/Sensor'
import type { SensorCurve } from '../flowchart/SensorCurve'
import { CurvePairFactory } from '../flowchart/CurvePairFactory'

export default class ExBaseFlowchartController extends Controller {
  static FULL_CIRCLE_RADIANS = 2 * Math.PI
  static ORIGINAL_HEIGHT = 424 // The height that the x,y sensor locations are based on

  declare readonly canvasTarget: HTMLCanvasElement
  declare readonly backgroundTarget: HTMLImageElement
  declare readonly tooltipTargets: HTMLInputElement[]

  declare dataValue: Data

  declare readonly hideClass: string

  declare _visits: string
  declare _width: number
  declare _height: number
  declare _ctx: CanvasRenderingContext2D | null
  declare _curves: SensorCurve[]
  declare _sensors: Sensor[]
  declare _maxVisits: number
  declare _minVisits: number
  declare _scaledData: Data
  declare _periodIndex: string
  declare _hoveredCurveAllocationId: number
  declare _hoveredCurvePairId: number
  declare _mouseX: number
  declare _mouseY: number
  declare _targetType: 'ExAllocation' | 'ExGroup' | undefined

  static values = { data: Object }
  static targets = [
    'canvas', 'tooltip', 'background'
  ]

  static classes = ['hide']

  connect (): void {
    this._drawLoop = this._drawLoop.bind(this) // So requestAnimationFrame calls in the correct context
    this._ctx = this.canvasTarget.getContext('2d')
    this._periodIndex = '0'
    const init = (): void => {
      this._setup()
      this._addMouseTracker()
      this._drawLoop()
    }
    if (this.backgroundTarget.complete) {
      init()
    } else {
      this.backgroundTarget.addEventListener('load', init.bind(this))
    }
  }

  _drawLoop (): void {
    if (this._ctx === null) return
    this._ctx.clearRect(0, 0, this._width, this._height)
    this._ctx.drawImage(this.backgroundTarget, 0, 0, this._width, this._height)
    this._drawSensorBackgrounds()
    this._checkForHoveredCurve()
    this._drawCurves()
    this._drawSensorForegrounds()
    this._displayTooltip()
    requestAnimationFrame(this._drawLoop)
  }

  _addCurves (): void {
    this._scaledData[this._periodIndex].forEach(([sensorOne, sensorTwo]) => {
      if (sensorOne[this._visits] === 0 || sensorTwo[this._visits] === 0 || sensorOne.target_type !== this._targetType) return
      const sOneInfo = { x: sensorOne.x, y: sensorOne.y, value: sensorOne[this._visits], allocationId: sensorOne.target_id, pairId: sensorOne.pair_id }
      const sTwoInfo = { x: sensorTwo.x, y: sensorTwo.y, value: sensorTwo[this._visits], allocationId: sensorTwo.target_id, pairId: sensorTwo.pair_id }
      const curvePairFactory = new CurvePairFactory(sOneInfo, sTwoInfo, this._minVisits, this._maxVisits)
      curvePairFactory.addCurvesTo(this._curves)
    })
  }

  _addSensors (): void {
    const uniqueSensors: Record<string, { x: number, y: number }> = {}
    this._scaledData[this._periodIndex].forEach(([sensorOne, sensorTwo]) => {
      if (sensorOne.target_type !== this._targetType) return
      uniqueSensors[sensorOne.name] = { x: sensorOne.x, y: sensorOne.y }
      uniqueSensors[sensorTwo.name] = { x: sensorTwo.x, y: sensorTwo.y }
    })
    this._sensors = Object.entries(uniqueSensors).map(([name, location]) => new Sensor(name, location))
  }

  _drawCurve (curve: SensorCurve, hovered: boolean): void {
    if (this._ctx === null) return
    const [startPoint, controlPoint, endPoint] = curve.points
    this._ctx.beginPath()
    this._ctx.moveTo(startPoint.x, startPoint.y)
    this._ctx.quadraticCurveTo(controlPoint.x, controlPoint.y, endPoint.x, endPoint.y)
    this._ctx.strokeStyle = curve.colour
    this._ctx.lineWidth = hovered ? 4 : 2
    this._ctx.globalAlpha = hovered ? 1 : 0.5
    this._ctx.stroke()
    this._ctx.globalAlpha = 1.0
    this._ctx.closePath()
  }

  _drawCurves (): void {
    this._curves.forEach((curve) => {
      curve.travel()
      if (this._shouldNotDrawCurve(curve)) return
      const hovered = curve.pairId === this._hoveredCurvePairId
      this._drawCurve(curve, hovered)
      this._drawTravelingCircles(curve.circlePositions, curve.colour, hovered)
    })
  }

  _checkForHoveredCurve (): void {
    let hoveredCurveAllocationId: number = -1
    let hoveredCurvePairId: number = -1

    this._curves.forEach((curve) => {
      if (this._ctx === null) return
      const [startPoint, controlPoint, endPoint] = curve.points
      this._ctx.beginPath()
      this._ctx.moveTo(startPoint.x, startPoint.y)
      this._ctx.quadraticCurveTo(controlPoint.x, controlPoint.y, endPoint.x, endPoint.y)
      this._ctx.lineWidth = 10
      if (this._ctx.isPointInStroke(this._mouseX * devicePixelRatio, this._mouseY * devicePixelRatio)) {
        hoveredCurveAllocationId = curve.allocationId
        hoveredCurvePairId = curve.pairId
      }
    })
    this._setHoveredCurveIds(hoveredCurveAllocationId, hoveredCurvePairId)
  }

  _setHoveredCurveIds (allocationId: number, pairId: number): void {
    this._hoveredCurveAllocationId = allocationId
    this._hoveredCurvePairId = pairId
  }

  _displayTooltip (): void {
    this.tooltipTargets.forEach((tooltipTarget) => {
      const { allocationId, pairId, periodIndex, visitType } = tooltipTarget.dataset
      if (Number(allocationId) === this._hoveredCurveAllocationId && Number(pairId) === this._hoveredCurvePairId && visitType === this._visits && periodIndex === this._periodIndex) {
        tooltipTarget.style.removeProperty('right')
        tooltipTarget.classList.remove(this.hideClass)
        const displayOnRightSide = (this._mouseX < this.canvasTarget.getBoundingClientRect().width - this._mouseX)
        if (displayOnRightSide) tooltipTarget.style.right = `-${this.canvasTarget.getBoundingClientRect().width}px`
      } else {
        tooltipTarget.classList.add(this.hideClass)
      }
    })
  }

  _shouldNotDrawCurve (_: SensorCurve): boolean {
    return false
  }

  _drawPoint (point: Point, size: number, colour: string, borderColour: string | null = null): void {
    if (this._ctx === null) return
    this._ctx.beginPath()
    this._ctx.arc(point.x, point.y, size, 0, ExBaseFlowchartController.FULL_CIRCLE_RADIANS)
    this._ctx.fillStyle = colour
    this._ctx.fill()
    if (borderColour !== null) {
      this._ctx.strokeStyle = borderColour
      this._ctx.lineWidth = 1
      this._ctx.stroke()
    }
    this._ctx.closePath()
  }

  _drawSensorBackgrounds (): void {
    this._sensors.forEach(({ name, location }) => {
      this._drawText(name, location.x, location.y - 20)
      this._drawPoint(location, 12, 'rgba(206, 196, 215, 0.8)', '#8366c2')
    })
  }

  _drawSensorForegrounds (): void {
    this._sensors.forEach(({ location }) => {
      this._drawPoint(location, 3, '#8366c2')
    })
  }

  _drawText (text: string, x: number, y: number): void {
    if (this._ctx === null) return
    this._ctx.fillStyle = '#000'
    this._ctx.font = 'bold 10px Arial'
    this._ctx.textAlign = 'center'
    this._ctx.fillText(text, x, y)
  }

  _drawTravelingCircles (circlePoints: Point[], colour: string, hovered: boolean): void {
    const size = hovered ? 4 : 2
    circlePoints.forEach((point) => {
      this._drawPoint(point, size, colour)
    })
  }

  _setup (): void {
    this._setWidthAndHeight()
    this._setScaledData()
    this._setCanvasScale()
    this._setupCurves()
    this._addSensors()
  }

  _setupCurves (): void {
    this._curves = []
    this._setRanges()
    this._addCurves()
  }

  _setCanvasScale (): void {
    if (this._ctx === null) return
    const devicePixelRatio = window.devicePixelRatio !== 0 ? window.devicePixelRatio : 1
    this.canvasTarget.width = this._width * devicePixelRatio
    this.canvasTarget.height = this._height * devicePixelRatio
    this.canvasTarget.style.width = `${this._width}px`
    this.canvasTarget.style.height = `${this._height}px`
    this._ctx.scale(devicePixelRatio, devicePixelRatio)
  }

  _setScaledData (): void {
    const originalWidth = ExBaseFlowchartController.ORIGINAL_HEIGHT * (this._width / this._height)

    this._scaledData = {}
    Object.entries(this.dataValue).forEach(([key, data]) => {
      this._scaledData[key] = data.map(([sensorOne, sensorTwo]) => {
        sensorOne.x = sensorOne.x * this._width / originalWidth
        sensorOne.y = sensorOne.y * this._height / ExBaseFlowchartController.ORIGINAL_HEIGHT
        sensorTwo.x = sensorTwo.x * this._width / originalWidth
        sensorTwo.y = sensorTwo.y * this._height / ExBaseFlowchartController.ORIGINAL_HEIGHT
        return [sensorOne, sensorTwo]
      })
    })
  }

  _setWidthAndHeight (): void {
    this._width = 720
    const aspectRatio = this.backgroundTarget.naturalWidth / this.backgroundTarget.naturalHeight
    this._height = Math.round(this._width / aspectRatio)
  }

  _setRanges (): void {
    const visitCounts = this.dataValue[this._periodIndex].flat().filter((sensor) => sensor.target_type === this._targetType).map((sensor) => sensor[this._visits]).filter(count => count !== 0)
    this._minVisits = Math.min(...visitCounts)
    this._maxVisits = Math.max(...visitCounts)
  }

  _addMouseTracker (): void {
    this._mouseX = this._mouseY = -1
    this.canvasTarget.addEventListener('mousemove', (event) => {
      this._mouseX = event.offsetX
      this._mouseY = event.offsetY
    })
  }
}
