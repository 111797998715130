import { Controller } from '@hotwired/stimulus'
import { type HTMLEvent } from '../types/html_event'

// Connects to data-controller="block-list-toggle"
export default class extends Controller {
  connect (): void {
    const inputs = document.querySelectorAll<HTMLInputElement>('.eventForm-eventDay-blocklist > input')
    inputs.forEach((input) => { input.dataset.onLoadValue = input.value })

    const checkboxes = document.querySelectorAll<HTMLInputElement>('.blocklistToggle')
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      this.hideOrShowBlocklist(checkbox, false)
    })
  }

  attemptNonEmptyString (val1: string | undefined, val2: string | undefined, useVal2: boolean): string {
    if (!useVal2 && (val1 != null) && val1.length > 0) return val1
    return val2 ?? ''
  }

  hideOrShowBlocklist (checkbox: HTMLInputElement, reset: boolean): void {
    const { containerId, dayStartsAtId, blockStartsAtId, blockEndsAtId } = checkbox.dataset

    const continerElm = document.getElementById(containerId ?? '') as HTMLElement
    const dayStartsAtElm = document.getElementById(dayStartsAtId ?? '') as HTMLInputElement
    const blockStartsAtElm = document.getElementById(blockStartsAtId ?? '') as HTMLInputElement
    const blockEndsAtElm = document.getElementById(blockEndsAtId ?? '') as HTMLInputElement

    if (!checkbox.checked) {
      blockStartsAtElm.value = ''
      blockEndsAtElm.value = ''
      continerElm.style.display = 'none'
      return
    }

    continerElm.style.display = 'grid'

    const dayStartsAtValue = dayStartsAtElm?.value
    // Setting in UTC so pulling string out with ISOString will give the correct date + time
    const dayDateTime = new Date(`${dayStartsAtValue}Z`)

    dayDateTime.setMinutes(dayDateTime.getMinutes() - 1)
    const defaultBlockEndsAt = dayDateTime.toISOString().slice(0, 19)

    dayDateTime.setMinutes(dayDateTime.getMinutes() - 29)
    const defaultBlockStartsAt = dayDateTime.toISOString().slice(0, 19)

    blockStartsAtElm.value = this.attemptNonEmptyString(blockStartsAtElm.dataset.onLoadValue, defaultBlockStartsAt, reset)
    blockEndsAtElm.value = this.attemptNonEmptyString(blockEndsAtElm.dataset.onLoadValue, defaultBlockEndsAt, reset)
  }

  toggle (event: HTMLEvent): void {
    const checkbox = event.currentTarget as HTMLInputElement
    this.hideOrShowBlocklist(checkbox, true)
  }
}
