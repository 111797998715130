export class LocalStorageManager {
  getItem (primaryKey: string, ...otherKeys: string[]): object | string | number | null {
    try {
      let localStorageItem = JSON.parse(localStorage.getItem(primaryKey) ?? '')
      otherKeys.forEach((key) => {
        if (typeof key !== 'string') throw new Error('Key is not a string!')
        localStorageItem = localStorageItem[key]
      })
      return localStorageItem
    } catch (error) {
      console.log(error)
      return null
    }
  }

  setItem (item: object | string | number, primaryKey: string, ...otherKeys: string[]): void {
    try {
      this.setDefaultValue(primaryKey)
      if (otherKeys.length > 0) {
        const localStorageItem = JSON.parse(localStorage.getItem(primaryKey) ?? '')
        const lastKey = otherKeys.pop() as string
        let pointer = localStorageItem
        otherKeys.forEach((key) => {
          if (typeof key !== 'string') throw new Error('Key is not a string!')
          pointer[key] ??= {}
          pointer = pointer[key]
        })
        pointer[lastKey] = item
        localStorage.setItem(primaryKey, JSON.stringify(localStorageItem))
      } else {
        localStorage.setItem(primaryKey, JSON.stringify(item))
      }
    } catch (error) {
      console.log(error)
    }
  }

  setDefaultValue (primaryKey: string): void {
    if (localStorage.getItem(primaryKey) === null) localStorage.setItem(primaryKey, '{}')
  }
}
