import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js'
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap'
import ChartColours from '../helpers/chart_colours'

// Connects to data-controller="ex-treemap"
export default class extends Controller {
  declare treemap: any
  declare visits: 'all' | 'engaged'
  declare treemapData: [{ points: [] }?]

  static targets = ['noData']
  declare readonly noDataTarget: HTMLElement

  connect (): void {
    const canvasElement = document.getElementById('ex-treemap') as HTMLCanvasElement
    if (canvasElement.parentElement !== null) {
      canvasElement.parentElement.style.height = '400px'
    }
    this.treemapData = []
    Chart.register(TreemapController, TreemapElement)

    const config: any = {
      type: 'treemap',
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false }
        }
      },
      data: {
        datasets: [
          {
            labels: {
              align: 'left',
              display: true,
              padding: 15,
              position: 'top',
              font: [{ size: 16 }, { size: 16, weight: 'bold' }],
              formatter (ctx: { type: string, raw: { _data: { title: string }, v: string } }): string[] {
                return [ctx.raw._data.title, `${ctx.raw.v}%`]
              }
            },
            tree: [],
            backgroundColor: (ctx: any) => {
              if (ctx.type !== 'data') {
                return 'transparent'
              }
              return ctx.raw._data.backgroundColour
            },
            borderWidth: 1,
            key: 'value'
          }
        ]
      }
    }

    this.treemap = new Chart(canvasElement, config)
  }

  setTreemapData ({ detail: { data, visits } }: any): void {
    this.treemapData = data
    this.visits = visits
    this.nextFrame({ detail: 0 })
  }

  // this is called internally and by ex_heatmap_controller
  nextFrame ({ detail: currentFrame }: { detail: number }): void {
    const heatmapData = this.treemapData[currentFrame]?.points
    if (heatmapData === undefined) return

    if (heatmapData.length > 0) {
      this.noDataTarget.hidden = true
      const totalAll = heatmapData.map((h: any) => h.all_value).reduce((a: number, b: number) => a + b)
      const totalEngaged = heatmapData.map((h: any) => h.engaged_value).reduce((a: number, b: number) => a + b)
      const treemapData = [...new Set(heatmapData.map((h: any) => h.ex_allocation_id))].map((id: any, index: number) => {
        const allocationData: any = heatmapData.filter((h: any) => h.ex_allocation_id === id)
        const colorIndex = id % ChartColours.length
        return {
          name: allocationData[0].ex_allocation_name,
          backgroundColour: ChartColours[colorIndex],
          all: Math.round((allocationData.map((h: any) => h.all_value).reduce((a: number, b: number) => a + b) / totalAll) * 100),
          engaged: Math.round((allocationData.map((h: any) => h.engaged_value).reduce((a: number, b: number) => a + b) / totalEngaged) * 100)
        }
      })
      this.treemap.data.datasets[0].tree = treemapData.map((d) => { return { value: d[this.visits], title: d.name, backgroundColour: d.backgroundColour } })
      this.treemap.update()
    } else {
      this.noDataTarget.hidden = false
    }
  }
}
