import type { HTMLEvent } from '../types/html_event'
import type { Statistic } from '../types/line_chart'
import LineChartBase from './line_chart_base'
// Connects to data-controller="apex-line-chart"
export default class extends LineChartBase {
  connect (): void {
    this.lineChartStatisticsValue = this.dataValue
    this.dataValue = {
      datasets: this.dataValue.datasets.hour
    }
    super.connect()
  }

  toggleLines ({ target }: HTMLEvent): void {
    let matcher = /./
    if (target.id === 'show-in') matcher = /in$/
    if (target.id === 'show-out') matcher = /out$/
    const legend = this.chart.legend
    if (legend === undefined) return
    legend.legendItems?.forEach((li: any) => {
      if (li.text.match(matcher) != null) {
        legend.chart.show(li.datasetIndex)
      } else {
        legend.chart.hide(li.datasetIndex)
      }
    })
  }

  setStatistics ({ detail: { period } }: Statistic): void {
    this.chart.data.datasets = this.lineChartStatisticsValue.datasets[period]
    this.chart.update()
  }

  legend (): any {
    return {
      position: 'bottom',
      onClick: (e: any, legendItem: any, legend: any) => {
        const radio = document.getElementById('show-all') as HTMLInputElement
        radio.checked = true
        const index = legendItem.datasetIndex
        const ci = legend.chart
        if (ci.isDatasetVisible(index) === true) {
          ci.hide(index)
          legendItem.hidden = true
        } else {
          ci.show(index)
          legendItem.hidden = false
        }
      }
    }
  }
}
