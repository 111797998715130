import type { Point } from '../types/flowchart'

export class SensorCurve {
  declare _traveledDistance: number
  declare _colour: string
  declare _startPoint: Point
  declare _controlPoint: Point
  declare _endPoint: Point
  declare _initialPositions: number[]
  declare _movementPerFrame: number
  declare _value: number
  declare _allocationId: number
  declare _pairId: number

  constructor () {
    this._traveledDistance = 0
  }

  get circlePositions (): Point[] {
    return this._initialPositions.map((position) => this._quadraticBezierPoint(position))
  }

  get colour (): string {
    return this._colour
  }

  set colour (colour) {
    this._colour = colour
  }

  get points (): Point[] {
    return [this._startPoint, this._controlPoint, this._endPoint]
  }

  set points (points: Point[]) {
    this._startPoint = points[0]
    this._controlPoint = points[1]
    this._endPoint = points[2]
  }

  get value (): number {
    return this._value
  }

  set value (value) {
    this._value = value
  }

  get allocationId (): number {
    return this._allocationId
  }

  set allocationId (id: number) {
    this._allocationId = id
  }

  get pairId (): number {
    return this._pairId
  }

  set pairId (id: number) {
    this._pairId = id
  }

  travel (): void {
    this._traveledDistance = this._addWithWrap(this._traveledDistance, this._movementPerFrame)
  }

  setPositionInfo ({ positions, movementPerFrame }: { positions: number[], movementPerFrame: number }): void {
    this._initialPositions = positions
    this._movementPerFrame = movementPerFrame
  }

  // Takes 2 numbers between 0.0 and 1.0 and returns the result wrapped around 1
  _addWithWrap (numOne: number, numTwo: number): number {
    const result = numOne + numTwo
    if (result > 1) return result - 1
    return result
  }

  // Returns the point on the curve based on the fraction of the distance between the start and end points
  _quadraticBezierPoint (initialPosition: number): Point {
    const position = this._addWithWrap(initialPosition, this._traveledDistance)
    const remaining = 1 - position
    const curvePoint = { x: 0, y: 0 }
    const axes: Array<'x' | 'y'> = ['x', 'y']
    axes.forEach((axis) => {
      const startContrib = remaining * remaining * this._startPoint[axis]
      const controlContrib = 2 * remaining * position * this._controlPoint[axis]
      const endContrib = position * position * this._endPoint[axis]

      curvePoint[axis] = startContrib + controlContrib + endContrib
    })

    return curvePoint
  }
}
