import Chart from 'stimulus-chartjs'
import 'chartjs-adapter-date-fns'
import { enGB } from 'date-fns/locale'
import type { Chart as ChartType } from 'chart.js'
import type { Options } from '../types/line_chart'

// Connects to data-controller="ex-line-chart"
export default class extends Chart {
  declare chart: ChartType

  connect (): void {
    super.connect()
    if (this.chart.canvas.parentElement !== null) {
      this.chart.canvas.parentElement.style.height = '400px'
    }
  }

  legend (): any {
    return {
      position: 'bottom'
    }
  }

  scales (): any {
    return {
      y: { beginAtZero: true },
      x: { display: false, parsing: false, type: 'time' }
    }
  }

  get defaultOptions (): Options {
    return {
      adapters: {
        date: {
          locale: enGB
        }
      },
      animation: false,
      pointRadius: 5,
      maintainAspectRatio: false,
      plugins: {
        legend: this.legend(),
        tooltip: {
          callbacks: {
            title: (context: any) => {
              return context[0].raw.tooltip
            }
          }
        }
      },
      scales: this.scales()
    }
  }
}
