import { Controller } from '@hotwired/stimulus'
import type { HTMLInputEvent } from '../types/html_event'

export default class extends Controller {
  static targets = ['outCountInfo']

  readonly outCountInfoTargets!: HTMLElement[]

  toggleShowOut (event: HTMLInputEvent): void {
    const show: boolean = event.target.checked
    this.outCountInfoTargets.forEach((el: HTMLElement) => { el.hidden = !show })
  }
}
