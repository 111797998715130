import type { CurveInfo, Point } from '../types/flowchart'
import { SensorCurve } from './SensorCurve'

export class CurvePairFactory {
  declare _sensorOnePoint: Point
  declare _sensorTwoPoint: Point
  declare _controlPointOne: Point
  declare _controlPointTwo: Point
  declare _sensorOneCurve: SensorCurve
  declare _sensorTwoCurve: SensorCurve
  declare _distance: number
  declare _normalAngle: number

  static NINETY_DEGREES_RADIANS = Math.PI / 2
  static MAX_TRAVELING_CIRCLES_PER_PIXEL = 0.05
  static CURVE_BOW_PER_PIXEL = 0.1

  constructor (sensorOne: CurveInfo, sensorTwo: CurveInfo, minVisits: number, maxVisits: number) {
    this._sensorOnePoint = { x: sensorOne.x, y: sensorOne.y }
    this._sensorTwoPoint = { x: sensorTwo.x, y: sensorTwo.y }
    this._setDistanceAndNormalAngle()
    this._setControlPoints()
    this._sensorOneCurve = new SensorCurve()
    this._sensorTwoCurve = new SensorCurve()
    this._buildCurve(
      this._sensorOneCurve,
      this._sensorOnePoint,
      this._sensorTwoPoint,
      this._controlPointOne,
      sensorOne.value / maxVisits,
      (sensorOne.value - minVisits) / (maxVisits - minVisits),
      sensorOne.value,
      sensorOne.allocationId,
      sensorOne.pairId
    )
    this._buildCurve(
      this._sensorTwoCurve,
      this._sensorTwoPoint,
      this._sensorOnePoint,
      this._controlPointTwo,
      sensorTwo.value / maxVisits,
      (sensorTwo.value - minVisits) / (maxVisits - minVisits),
      sensorOne.value,
      sensorTwo.allocationId,
      sensorTwo.pairId
    )
  }

  addCurvesTo (array: SensorCurve[]): void {
    array.push(this._sensorOneCurve)
    array.push(this._sensorTwoCurve)
  }

  _buildCurve (curve: SensorCurve, start: Point, end: Point, control: Point, proportion: number, strength: number, value: number, allocationId: number, pairId: number): void {
    curve.points = [start, control, end]
    curve.value = value
    curve.setPositionInfo({
      positions: this._circleInitialPositions(proportion),
      movementPerFrame: 1 / this._distance * proportion
    })
    curve.colour = this._curveColour(strength)
    curve.allocationId = allocationId
    curve.pairId = pairId
  }

  _circleInitialPositions (proportion: number): number[] {
    const nCircles = Math.round(this._distance * CurvePairFactory.MAX_TRAVELING_CIRCLES_PER_PIXEL * proportion)
    return this._generateInitialPositions(nCircles)
  }

  _curveColour (strength: number): string {
    const green = 1 - strength
    return `rgb(255, ${green * 255}, 0)`
  }

  // Based on the fraction of the distance between the start and end points
  _generateInitialPositions (nTravelingCircles: number): number[] {
    const arr = []
    for (let i = 0; i < nTravelingCircles; i++) {
      arr.push(i / (nTravelingCircles - 1))
    }
    return arr
  }

  _setControlPoints (): void {
    const midPoint = {
      x: (this._sensorOnePoint.x + this._sensorTwoPoint.x) / 2,
      y: (this._sensorOnePoint.y + this._sensorTwoPoint.y) / 2
    }
    const curveBowAmount = Math.round(this._distance * CurvePairFactory.CURVE_BOW_PER_PIXEL)
    const offsetX = curveBowAmount * Math.cos(this._normalAngle)
    const offsetY = curveBowAmount * Math.sin(this._normalAngle)
    this._controlPointOne = {
      x: midPoint.x + offsetX,
      y: midPoint.y + offsetY
    }
    this._controlPointTwo = {
      x: midPoint.x - offsetX,
      y: midPoint.y - offsetY
    }
  }

  _setDistanceAndNormalAngle (): void {
    const deltaX = this._sensorOnePoint.x - this._sensorTwoPoint.x
    const deltaY = this._sensorOnePoint.y - this._sensorTwoPoint.y
    this._distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
    this._normalAngle = Math.atan2(deltaY, deltaX) + CurvePairFactory.NINETY_DEGREES_RADIANS
  }
}
