import Chart from 'stimulus-chartjs'
import type { Chart as ChartType } from 'chart.js'

export default class extends Chart {
  declare chart: ChartType

  connect (): void {
    this.typeValue = 'bar'
    super.connect()
  }

  scales (): any {
    return {
      y: {
        ticks: {
          stepSize: 60,
          callback: (context: any) => `${String(Math.floor(context / 60)).padStart(2, '0')}m`
        }
      },
      x: {
        ticks: {
          display: (context: any) => context.scale.ticks.length < 7
        }
      }
    }
  }

  tooltip (): any {
    return {
      callbacks: {
        title: (context: any) => {
          const seconds = context[0].raw % 60
          const minutes = Math.floor((context[0].raw - seconds) / 60)
          return `${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`
        },
        label: (context: { dataset: { label: string }, label: string }) => `${context.dataset.label} - ${context.label}`
      }
    }
  }
}
