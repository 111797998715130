import { Controller } from '@hotwired/stimulus'
import mapData from '../heatmap/map_data'

// Connects to data-controller="ex-realtime"
export default class extends Controller {
  static targets = [
    'visitsDropdown', 'visitsDropdownButton', 'selectedVisits'
  ]

  static values = { realtimeurl: String }

  declare readonly visitsDropdownTarget: HTMLElement
  declare readonly visitsDropdownButtonTarget: HTMLElement
  declare readonly selectedVisitsTarget: HTMLElement

  declare realtimeurlValue: string

  declare currentVisits: HTMLElement
  declare visits: string | undefined
  declare json: any

  connected: boolean = false

  async connect (): Promise<void> {
    this.currentVisits = this.selectedVisitsTarget
    this.visits = this.currentVisits.dataset.visits
    this.connected = true
    await this.poll()
  }

  disconnect (): void {
    this.connected = false
  }

  async getResponse (): Promise<void> {
    if (document.hidden) return

    const response = await fetch(this.realtimeurlValue, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })

    if (response.ok) {
      this.json = await response.json()
      this.setData()
    }
  }

  async poll (): Promise<void> {
    await this.getResponse()

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (this.connected) setTimeout(this.poll.bind(this), 5000)
  }

  setData (): void {
    // The timezone doesn't matter in this context, as we are not displaying timestamps
    const visits = this.visits === undefined ? 'all' : this.visits
    const mappedData = mapData([this.json.heatmap], visits, 'UTC')
    this.dispatch('setHeatmapData', { detail: mappedData })
    this.dispatch('setFlowchartData', { detail: this.json.flowchart })
  }

  setVisits ({ target }: any): void {
    const { visits } = target.dataset
    if (visits !== this.visits) {
      this.currentVisits.classList.remove('ex-statistics-dropdownItem-selected')
      target.classList.add('ex-statistics-dropdownItem-selected')
      this.currentVisits = target
      this.visits = visits
      this.visitsDropdownButtonTarget.innerHTML = target.innerHTML
      this.setVisitsOnFlowchartController()
      this.setData()
    }
    this.toggleVisitsDropdown()
  }

  setVisitsOnFlowchartController (): void {
    this.dispatch('setVisitsOnFlowchartController', { detail: this.visits })
  }

  toggleVisitsDropdown (): void {
    this.visitsDropdownTarget.hidden = !this.visitsDropdownTarget.hidden
  }
}
