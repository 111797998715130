import { GridStack, type GridStackNode } from 'gridstack'
import { Controller } from '@hotwired/stimulus'
import { getCSRFToken } from '../helpers/get_csrf_token'

export default class extends Controller {
  static values = {
    updateDashboardUrl: String
  }

  declare readonly updateDashboardUrlValue: string
  declare grid: any

  connect (): void {
    const options = {
      float: true,
      column: 6,
      margin: 3,
      cellHeight: '300px'
    }

    this.grid = GridStack.init(options)

    this.addOnChangeListener()
  }

  addOnChangeListener (): void {
    const generateBody = this.generateBody
    const updateDashboard = this.updateDashboard
    const url = this.updateDashboardUrlValue

    this.grid.on('change', async function (_: Event, items: GridStackNode[]) {
      const body = generateBody(items)
      await updateDashboard(url, body)
    })
  }

  generateBody (items: GridStackNode[]): object {
    const cardSelectionsAttributes = items.map(({ id, h, w, x, y }) => ({ id, x, y, w, h }))
    return {
      dashboard: {
        card_selections_attributes: cardSelectionsAttributes
      }
    }
  }

  async updateDashboard (url: string, body: object): Promise<Response> {
    return await fetch(url, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCSRFToken()
      },
      body: JSON.stringify(body)
    })
  }
}
